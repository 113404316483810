import axios from "axios";

export const productsData = () => {
  return axios
    .get("/feature_products")
    .then((response) => {
      // console.log(response.data);
      return response;
    });
};
