<template>
  <section class="forget_password">
    <div class="container">
      <form class="auth" @submit.prevent="SubmitForm">
        <h2>{{ $t("Forgot Your Password?") }}</h2>
        <p class="text">
          {{
            $t(
              "Enter Mobile Phone Number And Set A New Password"
            )
          }}
        </p>
        <div class="form-group">
          <vue-tel-input v-model="phone" class="form-control" v-bind="bindProps"></vue-tel-input>
        </div>
        <button type="submit" class="btn submit_btn">
          {{ $t("Continue") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  data() {
    return {
      phone: "",
      bindProps: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          placeholder: this.$t("phone"),
        },
        validCharactersOnly: true
      },
    };
  },
  components: {
    VueTelInput,
  },
  methods: {
    SubmitForm() {
      if (this.phone === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
          timer: 6000,
        });
      } else {
        const formData = new FormData();
        formData.append('phone_code', this.phone.split(' ')[0].replace('+', ''));
        formData.append('phone', this.phone.split(' ').slice(1).join(' ').replace(/\s/g, ''));
        axios
          .post("/forgot_password", formData)
          .then((data) => {
            // console.log(data);
            if (data.data.status == true) {
              //Perform Success Action
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              const user = {
                phone_code: this.phone.split(' ')[0].replace('+', ''),
                phone: this.phone.split(' ').slice(1).join(' ').replace(/\s/g, '')
              }
              localStorage.setItem("user", JSON.stringify(user));
              this.$router.push("/otp");
            } else {
              //Perform Success Action
              Swal.fire({
                type: "error",
                position: "center",
                icon: "error",
                title: data.data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              this.loading = false;
            }
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: error.response.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            // this.$router.push({ path: "/teachers" });
            this.loading = false;
          })
          .finally(() => {
            //Perform action in always
          });
      }
    },
  },
};
</script>

<style scoped>
.auth h2 {
  text-align: start;
}
.auth .text {
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
}
.auth .submit_btn {
  margin-top: 0;
}
</style>
