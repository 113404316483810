import { createRouter, createWebHistory } from "vue-router";
import store from '../src/store/index.js';

//import website
import indexPage from "../src/views/index.vue";
import productsPage from "../src/views/products.vue";
import productPage from "../src/views/product_details.vue";
import aboutPage from "../src/views/about.vue";
import contactPage from "../src/views/contact.vue";
import registerPage from "../src/views/auth/register.vue";
import verifyPage from "../src/views/auth/verify.vue";
import loginPage from "../src/views/auth/login.vue";
import forgetPasswordPage from "../src/views/auth/forget_password.vue";
import otpPage from "../src/views/auth/otp.vue";
import resetPasswordPage from "../src/views/auth/reset_password.vue";
import termsPage from "../src/views/terms.vue";
import privacyPage from "../src/views/privacy.vue";
import faqPage from "../src/views/faq.vue";
import comparePage from "../src/views/compare.vue";
import trackPage from "../src/views/track.vue";
import trackDetailsPage from "../src/views/trackDetails.vue";


import profilePage from "../src/views/profile/profile.vue";
import addressPage from "../src/views/profile/address.vue";
import passwordPage from "../src/views/profile/password.vue";
import notificationPage from "../src/views/profile/notification.vue";
import cartPage from "../src/views/cart.vue";
import orderPage from "../src/views/order.vue";
import myordersPage from "../src/views/profile/order.vue";
import myordersDetailsPage from "../src/views/profile/order_details.vue";
import wishListPage from "../src/views/profile/wishlist.vue";
import productReviewsPage from "../src/views/profile/product_reviews.vue";


import NotFound from "../src/views/error.vue";


const routes = [
    // Website routes
    { path: "/", component: indexPage, name: "index" },
    { path: "/products", component: productsPage, name: "products" },
    { path: "/product/:id", component: productPage, name: "product" },
    { path: "/about", component: aboutPage, name: "about" },
    { path: "/contact", component: contactPage, name: "contact" },
    { path: "/register", component: registerPage, name: "register", meta: { guest: true } },
    { path: "/verify", component: verifyPage, name: "verify", meta: { guest: true } },
    { path: "/login", component: loginPage, name: "login", meta: { guest: true } },
    { path: "/forget_password", component: forgetPasswordPage, name: "forgetPassword", meta: { guest: true } },
    { path: "/otp", component: otpPage, name: "otp", meta: { guest: true } },
    { path: "/reset_password", component: resetPasswordPage, name: "reset_password", meta: { guest: true } },
    { path: "/terms", component: termsPage, name: "terms" },
    { path: "/privacy", component: privacyPage, name: "privacy" },
    { path: "/faq", component: faqPage, name: "faq" },
    { path: "/compare", component: comparePage, name: "compare" },
    { path: "/track", component: trackPage, name: "track" },
    { path: "/track/:id", component: trackDetailsPage, name: "trackDetails" },
    { path: "/404", component: NotFound},
    { path: "/:catchAll(.*)", redirect: "/404"},
    

    { path: "/profile", component: profilePage, name: "profile", meta: { requiresAuth: true }},
    { path: "/address", component: addressPage, name: "address", meta: { requiresAuth: true }},
    { path: "/password", component: passwordPage, name: "password", meta: { requiresAuth: true }},
    { path: "/notification", component: notificationPage, name: "notification", meta: { requiresAuth: true }},
    { path: "/cart", component: cartPage, name: "cart", meta: { requiresAuth: true } },
    { path: "/order", component: orderPage, name: "order", meta: { requiresAuth: true } },
    { path: "/my_order", component: myordersPage, name: "my_order", meta: { requiresAuth: true } },
    { path: "/my_order/:id", component: myordersDetailsPage, name: "my_order_details", meta: { requiresAuth: true } },
    { path: "/wishlist", component: wishListPage, name: "wishList", meta: { requiresAuth: true }},
    { path: "/reviews", component: productReviewsPage, name: "reviews", meta: { requiresAuth: true }},

];

const router = createRouter({
    routes,
    history: createWebHistory(),
    hashbang: false,
    mode: 'html5',
    linkActiveClass: "active",
    // linkExactActiveClass: "exact-active",
    scrollBehavior() {
        // Scroll to the top of the page
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
        next();
        return;
        }
        next("/login");
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
        next("/");
        return;
        }
        next();
    } else {
        next();
    }
});  

export default router;