<template>
    <section class="track">
        <div class="track_order" v-on:submit.prevent="SubmitForm">
            <h3 class="title">{{$t("Track Your Order")}}</h3>
            <div class="form-group">
                <input type="number" name="" id="" v-model="track_number" class="form-control" :placeholder="$t('Order Number')">
            </div>
            <button type="submit" class="btn PrimaryButton" :disabled="track_number == ''" @click="trackOrder">{{$t("Track Order")}}</button>
        </div>
    </section>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    name: "track-order",
    data() {
        return {
            track_number: ""
        }
    },
    methods: {
        trackOrder() {
            if (this.track_number == "") {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("Please enter tarck order"),
                    showConfirmButton: true,
                    timer: 6000,
                });
            }
            else {
                this.$router.push(`/track/${this.track_number}`)
            }
        }
    },
}
</script>

<style>

</style>