<template>
  <div class="container">
    <form class="auth" @submit.prevent="register">
      <h2>{{ $t("Create Your Account") }}</h2>
      <!-- <GoogleLogin :callback="callback"/> -->
      <span class="or">{{ $t("or") }}</span>
      <div class="form-group">
        <input
          type="text"
          name=""
          id=""
          class="form-control"
          :placeholder="$t('name')"
          v-model="Form.name"
          maxlength="100"
          @input="filterInput"
        />
      </div>
      <div class="form-group">
        <vue-tel-input v-model="phone" class="form-control" v-bind="bindProps"></vue-tel-input>
      </div>
      <div class="form-group d-block">
        <input
          type="email"
          name=""
          id=""
          class="form-control"
          :placeholder="$t('email')"
          v-model="Form.email"
          @input="validateEmail"
          maxlength="100"
        />
        <div class="error-message text-danger">{{ emailError }}</div>
      </div>
      <div class="form-group d-block">
        <div class="form-group mb-2">
          <input
            :type="passwordFieldType"
            name=""
            id=""
            class="form-control"
            :placeholder="$t('password')"
            v-model="Form.password"
            @input="validatePassword"
            maxlength="30"
          />
          <button
            class="btn show_password"
            type="button"
            @click="switchVisibility"
            v-html="eye"
          ></button>
        </div>
        <!-- password validation message -->
        <div v-if="!isPasswordValid" class="error-message text-danger">
          {{$t("Password must contain at least one special character, one alphabet, one number, and be at least 8 characters long")}}
        </div>
        <!-- password validation message -->
      </div>
      <div class="form-group">
        <input
          :type="passwordFieldTypeConfirm"
          name=""
          id=""
          class="form-control"
          :placeholder="$t('Confirm Password')"
          v-model="Form.password_confirmation"
          maxlength="30"
        />
        <button
          class="btn show_password"
          type="button"
          @click="switchVisibilityConfirm"
          v-html="eyeConfirm"
        ></button>
      </div>
      <div class="d-flex justify-content-start align-items-center">
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              name=""
              id=""
              value="checkedValue"
              v-model="checkTerms"
            />
          </label>
        </div>
        <div class="forget_password_btn">
          {{ $t("I agree to") }}
          <span
            data-bs-toggle="modal"
            data-bs-target="#TermsModal">
            {{ $t("Terms of Service") }}
          </span>
          and
          <span
            data-bs-toggle="modal"
            data-bs-target="#PrivacyModal"
          >
             {{$t("privacy")}}
          </span>
        </div>
        <TermsModal />
        <PrivacyModal />
      </div>
      <button type="submit" class="btn submit_btn" :disabled="disableButton">
        {{ $t("Verify Mobile Number") }}
      </button>
      <div class="register_redirect">
        {{ $t("Already have an account ?") }}
        <router-link to="/login">{{ $t("Log in") }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";
import TermsModal from "@/components/include/modal/termsModal.vue";
import PrivacyModal from "@/components/include/modal/privacyModal.vue";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  data() {
    return {
      isPasswordValid: true,
      Form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        device_token: "12365478900000",
      },
      phone: "",
      checkTerms: false,
      passwordFieldType: "password",
      passwordFieldTypeConfirm: "password",
      eye: "<i class='fa-solid fa-eye'></i>",
      eyeConfirm: "<i class='fa-solid fa-eye'></i>",
      bindProps: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          showDialCode: false,
          placeholder: this.$t("phone"),
        },
        dropdownOptions: {
          showSearchBox: true,
          showFlags: true,
          showDialCodeInList: true,
        },
        validCharactersOnly: true
      },
      emailError: "",
      disableButton: false,
    };
  },
  components: {
    TermsModal,
    PrivacyModal,
    VueTelInput,
  },
  methods: {
    validatePassword() {
      const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      this.isPasswordValid = regex.test(this.Form.password);
    },
    filterInput() {
      this.Form.name = this.Form.name.replace(/[^a-zA-Z\s]/g, "");;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.eye =
        this.eye == "<i class='fa-solid fa-eye'></i>"
          ? "<i class='fa-solid fa-eye-slash'></i>"
          : "<i class='fa-solid fa-eye'></i>";
    },
    switchVisibilityConfirm() {
      this.passwordFieldTypeConfirm =
        this.passwordFieldTypeConfirm === "password" ? "text" : "password";
      this.eyeConfirm =
        this.eyeConfirm == "<i class='fa-solid fa-eye'></i>"
          ? "<i class='fa-solid fa-eye-slash'></i>"
          : "<i class='fa-solid fa-eye'></i>";
    },

    validateEmail() {
      // Regular expression for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.Form.email) {
        this.emailError = this.$t('Email is required');
      } else if (!emailPattern.test(this.Form.email)) {
        this.emailError = this.$t('Invalid email format');
      } else {
        this.emailError = ''; // Reset the error message if email is valid
      }
    },

    async register() {
      this.disableButton = true; //the disableButton begin
      this.loading = true; //the loading begin
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const formData = new FormData();
      Object.entries(this.Form).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('phone_code', this.phone.split(' ')[0].replace('+', ''));
      formData.append('phone', this.phone.split(' ').slice(1).join(' ').replace(/\s/g, ''));
      if (this.Form.name === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("name field is empty"),
        });
        this.disableButton = false;
      } else if (this.phone === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("phone field is empty"),
        });
        this.disableButton = false;
      } else if (this.Form.email === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("email field is empty"),
        });
        this.disableButton = false;
      }
      else if(!emailPattern.test(this.Form.email)) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Invalid email format"),
        });
        this.disableButton = false;
      } else if (!this.isPasswordValid) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Password must contain at least one special character, one alphabet, one number, and be at least 8 characters long"),
        });
        this.disableButton = false;
      } else if (this.Form.password === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("password field is empty"),
        });
        this.disableButton = false;
      } else if (this.Form.password_confirmation === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("password confirmation field is empty"),
        });
        this.disableButton = false;
      } else if (this.Form.password != this.Form.password_confirmation) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("check_password_same"),
        });
        this.disableButton = false;
      } else if (this.checkTerms === false) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("please_checked_terms"),
        });
        this.disableButton = false;
      } else {
        try {
          const response = await axios.post(`/register`, formData);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.Form = {
              phone: "",
              email: "",
              name: "",
              password: "",
              password_confirmation: "",
            };
            const userData = {
              name: response.data.data.name,
              phone: response.data.data.phone,
              phone_code: response.data.data.phone_code,
              email: response.data.data.email,
            };
            localStorage.setItem("user", JSON.stringify(userData))
            this.$router.push("/verify");
            this.error = null;
            this.disableButton = false;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
            this.disableButton = false;
          }
        } catch (error) {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.disableButton = false;
        } finally {
          this.disableButton = false;
        }
      }
    },
  },
};
</script>