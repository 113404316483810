<template>
  <breadcrumb :title="title" :subTitle="subTitle" :link="link" />
  <div class="container">
    <div class="row">
        <Sidebar />
        <Order />
    </div>
  </div>
</template>

<script>
import breadcrumb from "@/components/layouts/breadcrumb.vue";
import Sidebar from '@/components/layouts/sidebar.vue';
import Order from '@/components/include/profile/order/order.vue';
export default {
  data() {
    return {
      title: this.$t("my_order"),
      subTitle: "",
      link: "",
    };
  },
  components: { breadcrumb, Sidebar, Order },
};
</script>

<style>
</style>