<template>
  <div class="header" v-if="status == true && headers.length">
    <div class="container">
      <swiper
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        :loop="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        class="headerSwiper"
      >
        <swiper-slide v-for="(header, index) in headers" :key="index">
          <router-link to="/products">
            <img
              :src="header.image"
              alt="header"
            />
          </router-link>
          <!-- <div class="text_header">
            <h1 class="title">{{ header.title }}</h1>
            <router-link to="/products" class="btn">{{$t ("order_now")}}</router-link>
          </div> -->
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

import { headersData } from "@/api/index/header.js";

export default {
  data() {
    return {
      status: "",
      headers: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
  mounted() {
    headersData().then((response) => {
      this.status = response.data.status;
      this.headers = response.data.data;
      return response;
    });
  },
};
</script>