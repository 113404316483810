<template>
<!-- Modal -->
<div class="modal fade" id="TermsModal" tabindex="-1" aria-labelledby="TermsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="TermsModalLabel">{{$t("terms")}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="text" v-html="terms.text"></p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { termsData } from "@/api/static/terms.js";

export default {
  data() {
    return {
      terms: {},
    };
  },
  mounted() {
    termsData().then((response) => {
      this.terms = response.data.data;
      // console.log(response.data);
      return response;
    });
  },
};
</script>

<style>

</style>