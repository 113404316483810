<template>
  <section class="terms">
    <div class="container">
        <h2>{{$t("terms")}}</h2>
        <p class="text" v-html="terms.text"></p>
    </div>
  </section>
</template>


<script>
import { termsData } from "@/api/static/terms.js";

export default {
  data() {
    return {
      terms: {},
    };
  },
  mounted() {
    termsData().then((response) => {
      this.terms = response.data.data;
      // console.log(response.data);
      return response;
    });
  },
};
</script>
