<template>
  <!-- Modal -->
  <div
    class="modal modal_write_review fade"
    id="writereviewModal"
    tabindex="-1"
    aria-labelledby="writereviewModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="SubmitForm">
            <h1>{{$t("write_review")}}</h1>
            <rate :length="5" v-model="Form.rate" />
            <div class="form-group">
              <input
                type="text"
                name=""
                id=""
                class="form-control"
                v-model="Form.name"
                :placeholder="$t('your_name')"
                maxlength="100"
                :disabled="auth"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                name=""
                id=""
                class="form-control"
                v-model="Form.title"
                @input="filterSpecialCharacters"
                :placeholder="$t('title_review')"
                maxlength="100"
              />
            </div>
            <div class="form-group">
              <textarea name="" id="" rows="5" class="form-control" maxlength="500" @input="filterSpecialCharacters" :placeholder="$t('describe_experience')" v-model="Form.text"></textarea>
              <span class="max_length">{{Form.text.length}} / {{maxTextLength - Form.text.length}}</span>
            </div>
            <button type="submit" class="btn" :disabled="Form.name == '' || Form.rate == '' || Form.title == '' || Form.text == '' || disableButton">{{$t("submit_review")}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["product", "productData"],
  data() {
    return {
      Form: {
        product_id: this.$route.params.id,
        name: this.$store.getters['isAuthenticated'] ? JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).name : "",
        title: "",
        text: "",
        rate: "",
      },
      maxTextLength: 500,
      disableButton: false,
      auth: this.$store.getters['isAuthenticated']
    };
  },
  methods: {
    filterSpecialCharacters() {
      this.Form.title = this.Form.title.replace(/[^a-zA-Z0-9\u0600-\u06FF\s]/g, "");
      this.Form.text = this.Form.text.replace(/[^a-zA-Z0-9\u0600-\u06FF\s]/g, "");
    },

    SubmitForm() {
      this.disableButton = true; //the disableButton begin

      axios
        .post("/rates", this.Form)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            if (!this.auth) {
              this.Form.name = ""
            }
            this.Form.title = "",
            this.Form.text = "",
            this.Form.rate = ""
            this.disableButton = false;
            // this.productData()
          } else {
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            this.loading = false;
            this.disableButton = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: true,
            timer: 6000,
          });
          // this.$router.push({ path: "/teachers" });
          this.disableButton = false;
          this.loading = false;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
};
</script>

<style>
</style>