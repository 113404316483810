<template>
    <ul class="socialicon">
        <li v-if="whatsapp">
            <a target="_blank" :href="whatsapp"><i class="fab fa-whatsapp icon">
                </i></a>
        </li>
        <li v-if="facebook">
            <a target="_blank" :href="facebook"><i class="fa-brands fa-facebook-f icon"></i></a>
        </li>
        <li v-if="twitter">
            <a target="_blank" :href="twitter"><i class="fa-brands fa-twitter icon"></i></a>
        </li>
        <li v-if="instagram">
            <a target="_blank" :href="instagram"><i class="fa-brands fa-instagram icon"></i></a>
        </li>
        <li v-if="youtube">
            <a target="_blank" :href="youtube"><i class="fa-brands fa-youtube icon"></i></a>
        </li>
        <li v-if="linkedin">
            <a target="_blank" :href="linkedin"><i class="fa-brands fa-linkedin-in icon"></i></a>
        </li>
        <li v-if="telegram">
            <a target="_blank" :href="telegram"><i class="fa-brands fa-telegram icon"></i></a>
        </li>
        <li v-if="tiktok">
            <a target="_blank" :href="tiktok"><i class="fa-brands fa-tiktok icon"></i></a>
        </li>
    </ul>
</template>

<script>
import { contactData } from "@/api/static/contact.js";

export default {
    name: "social-media",
    data() {
        return {
            contact: "",
            address_ar: "",
            address_en: "",
            email: "",
            phone: "",
            whatsapp: "",
            facebook: "",
            twitter: "",
            instagram: "",
            youtube: "",
            linkedin: "",
            telegram: "",
            tiktok: "",
            map_link: "",
            contact_us_image: "",
        };
    },
    mounted() {
        contactData().then((response) => {
            this.contact = response.data.data;

            const addressArData = response.data.data.find(item => item.key === 'address_ar');
            this.address_ar = addressArData ? addressArData.value : null;

            const addressEnData = response.data.data.find(item => item.key === 'address_en');
            this.address_en = addressEnData ? addressEnData.value : null;

            const emailData = response.data.data.find(item => item.key === 'email');
            this.email = emailData ? emailData.value : null;

            const phoneData = response.data.data.find(item => item.key === 'phone');
            this.phone = phoneData ? phoneData.value : null;

            const whatsappData = response.data.data.find(item => item.key === 'whatsapp');
            this.whatsapp = whatsappData ? whatsappData.value : null;

            const facebookData = response.data.data.find(item => item.key === 'facebook');
            this.facebook = facebookData ? facebookData.value : null;

            const twitterData = response.data.data.find(item => item.key === 'twitter');
            this.twitter = twitterData ? twitterData.value : null;

            const youtubeData = response.data.data.find(item => item.key === 'youtube');
            this.youtube = youtubeData ? youtubeData.value : null;

            const linkedinData = response.data.data.find(item => item.key === 'linkedin');
            this.linkedin = linkedinData ? linkedinData.value : null;

            const instagramData = response.data.data.find(item => item.key === 'instagram');
            this.instagram = instagramData ? instagramData.value : null;

            const telegramData = response.data.data.find(item => item.key === 'telegram');
            this.telegram = telegramData ? telegramData.value : null;

            const tiktokData = response.data.data.find(item => item.key === 'tiktok');
            this.tiktok = tiktokData ? tiktokData.value : null;

            const mapLinkData = response.data.data.find(item => item.key === 'map_link');
            this.map_link = mapLinkData ? mapLinkData.value : null;

            const contactUsImageData = response.data.data.find(item => item.key === 'contact_us_image');
            this.contact_us_image = contactUsImageData ? contactUsImageData.value : null;

            this.$emit('dataLoaded', {
                address_ar: this.address_ar,
                address_en: this.address_en,
                email: this.email,
                phone: this.phone,
                map_link: this.map_link,
                contact_us_image: this.contact_us_image
            });
        });
    },
}
</script>