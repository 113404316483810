<template>
  <section class="forget_password">
    <div class="container">
      <form class="auth" @submit.prevent="SubmitForm">
        <h2 class="mb-4">{{ $t("reset your password") }}</h2>
        <div class="form-group d-block">
          <div class="form-group mb-2">
            <input
              :type="passwordFieldType"
              name=""
              id=""
              class="form-control"
              :placeholder="$t('password')"
              v-model="password"
              @input="validatePassword"
              maxlength="30"
            />
            <button
              class="btn show_password"
              type="button"
              @click="switchVisibility"
              v-html="eye"
            ></button>
          </div>
          <!-- password validation message -->
          <div v-if="!isPasswordValid" class="error-message text-danger">
            {{$t("Password must contain at least one special character, one alphabet, one number, and be at least 8 characters long")}}
          </div>
          <!-- password validation message -->
        </div>
        <div class="form-group">
          <input
            :type="passwordFieldTypeConfirm"
            name=""
            id=""
            class="form-control"
            :placeholder="$t('Confirm Password')"
            v-model="confirm_password"
            maxlength="30"
          />
          <button
            class="btn show_password"
            type="button"
            @click="switchVisibilityConfirm"
            v-html="eyeConfirm"
          ></button>
        </div>
        <button type="submit" class="btn submit_btn">
          {{ $t("save changes and sign in") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isPasswordValid: true,
      passwordFieldType: "password",
      passwordFieldTypeConfirm: "password",
      eye: "<i class='fa-solid fa-eye'></i>",
      eyeConfirm: "<i class='fa-solid fa-eye'></i>",
      password: "",
      confirm_password: ""
    };
  },
  methods: {
    validatePassword() {
      const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      this.isPasswordValid = regex.test(this.password);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.eye =
        this.eye == "<i class='fa-solid fa-eye'></i>"
          ? "<i class='fa-solid fa-eye-slash'></i>"
          : "<i class='fa-solid fa-eye'></i>";
    },
    switchVisibilityConfirm() {
      this.passwordFieldTypeConfirm =
        this.passwordFieldTypeConfirm === "password" ? "text" : "password";
      this.eyeConfirm =
        this.eyeConfirm == "<i class='fa-solid fa-eye'></i>"
          ? "<i class='fa-solid fa-eye-slash'></i>"
          : "<i class='fa-solid fa-eye'></i>";
    },
    SubmitForm() {
      if (this.password === "" || this.confirm_password === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
          timer: 6000,
        });
      }
      else if(this.password != this.confirm_password){
         Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("check_password"),
          timer: 6000,
        });
      } else if (!this.isPasswordValid) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Password must contain at least one special character, one alphabet, one number, and be at least 8 characters long"),
        });
      } else {
        const newPassword = {
          phone: JSON.parse(localStorage.getItem("user")).phone,
          phone_code: JSON.parse(localStorage.getItem("user")).phone_code,
          code: JSON.parse(localStorage.getItem("user")).code,
          password: this.password,
          password_confirmation: this.confirm_password
        }
        axios
          .post("/reset_password", newPassword)
          .then((data) => {
            // console.log(data);
            if (data.data.status == true) {
              //Perform Success Action
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              this.$router.push("/login");
              localStorage.removeItem("user");
            } else {
              //Perform Success Action
              Swal.fire({
                type: "error",
                position: "center",
                icon: "error",
                title: data.data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              this.loading = false;
            }
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: error.response.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            // this.$router.push({ path: "/teachers" });
            this.loading = false;
          })
          .finally(() => {
            //Perform action in always
          });
      }
    },
  },
};
</script>

<style scoped>
.auth h2 {
  text-align: start;
}
.auth .text {
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
}
.auth .submit_btn {
  margin-top: 0;
}
</style>
