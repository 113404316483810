<template>
  <h4>{{ $t("Current address") }}</h4>
  <div
    class="form-group"
    v-for="(address, index) in currentAddresses"
    :key="index"
  >
    <div class="row align-items-center">
      <div class="col-lg-3 col-md-10 col-12">
        <label for="">{{ $t("country") }}</label>
        <span class="form-control" :data-title="currentAddresses[index].city.country.title">{{ currentAddresses[index].city.country.title.length > 10 ? currentAddresses[index].city.country.title.substring(0, 10) + ' ...' : currentAddresses[index].city.country.title.substring(0, 10) }}</span>
      </div>
      <div class="col-lg-3 col-md-10 col-12">
        <label for="">{{ $t("city") }}</label>
        <span class="form-control" :data-title="currentAddresses[index].city.title">{{ currentAddresses[index].city.title.length > 10 ? currentAddresses[index].city.title.substring(0, 10) + ' ...' : currentAddresses[index].city.title.substring(0, 10) }}</span>
      </div>
      <div class="col-lg-3 col-md-10 col-12">
        <label for="">{{ $t("address") }} - {{ index + 1 }}</label>
        <span class="form-control" :data-title="currentAddresses[index].title">{{ currentAddresses[index].title.length > 10 ? currentAddresses[index].title.substring(0, 10) + ' ...' : currentAddresses[index].title.substring(0, 10) }}</span>
      </div>
      <div class="col-lg-3 col-md-10 col-12">
        <label for="" class="visabilty_none">{{$t("delete")}}</label>
        <button type="button" class="btn btn-danger delete_btn" @click="deleteAddress(address.id)">{{$t("delete")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    currentAddresses: Array,
    addressesData: Function
  },
  data() {
    return {
    };
  },
  methods: {
    deleteAddress(address_id) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(delete_id);
          axios.delete(`/delete_address/${address_id}`).then(({ data }) => {
            // console.log(result);
            if (data.status == true) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              this.addressesData()
              // console.log(data);
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: data.message,
                showConfirmButton: true,
                timer: 6000,
              });
            }
            return data;
          });
        }
      });
    },
  },
};
</script>

<style scoped>
h4 {
  font-family: "regular";
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.delete_btn {
  display: block;
  font-family: "semibold";
  font-size: .8rem;
  padding: .575rem 1.5rem;
}
.visabilty_none {
  visibility: hidden;
}
</style>
