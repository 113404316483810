<template>
  <section class="header_about">
    <div class="container">
        <img :src="about.image" alt="about header">
        <div class="text_header">
            <h1>{{$t("about_us")}}</h1>
        </div>
    </div>
  </section>
  <section class="about">
    <div class="container">
        <p class="text" v-html="about.text"></p>
    </div>
  </section>
  <brands />
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay, Pagination } from "swiper";

import "swiper/css/pagination";

import { aboutData } from "@/api/static/about.js";
import brands from "@/components/include/index/brands.vue";

export default {
  data() {
    return {
      about: {},
      brands: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    brands,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
  mounted() {
    aboutData().then((response) => {
      this.about = response.data.data;
      // console.log(response.data);
      return response;
    });
  },
};
</script>

<style scoped>
    .brands {
        margin-bottom: 8rem;
    }
</style>