<template>
  <div class="container">
    <div class="breadcrumb">
      <router-link to="/">{{ $t("home") }} ></router-link>

      <p v-if="link == ''">{{ title }}</p>
      <router-link v-else :to="link">{{ title }}</router-link>

      <p v-if="subTitle == ''"></p>
      <p v-else> > {{ subTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "subTitle", "link"],
};
</script>