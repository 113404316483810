<template>
  <section class="cart" v-if="cart.data.length">
    <div class="container">
        <h3 class="title_section">{{$t("My Shopping Cart")}} ({{cart.meta.total}})</h3>
        <div class="row">
            <div class="col-lg-9 col-md-12 col-12">
                <Cart :carts="cart.data" :callCartMethod="fetchCart" />
                <button class="btn remove_all_cart btn-danger" v-if="cart.data.length" @click="deleteAllCart">{{$t("delete_all_cart")}}</button>
                <div class="row mt-3">
                  <Bootstrap5Pagination
                    :data="cart"
                    :limit="limit"
                    class="mx-auto flex align-items-center justify-content-center pagination"
                    @pagination-change-page="fetchCart"
                    @click="ScrollUp"
                  >
                    <template v-slot:prev-nav>&lt;</template>
                    <template v-slot:next-nav>&gt;</template>
                  </Bootstrap5Pagination>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-12">
              <div class="cart_summary" v-if="cart.data.length">
                <h5 class="title">{{$t("cart_summary")}}</h5>
                <ul class="price">
                  <li>
                    <p class="title_price">{{$t("Subtotal")}}</p>
                    <!-- {{total_price.sub_total}} -->
                    <p class="text">0 {{$t("egp")}}</p>
                  </li>
                  <li>
                    <p class="title_price">{{$t("shipping")}}</p>
                    <!-- {{total_price.shipping}} -->
                    <p class="text">0 {{$t("egp")}}</p>
                  </li>
                  <li>
                    <p class="title_price">{{$t("vat_percentage")}}</p>
                    <!-- {{total_price.vat_percentage}} -->
                    <p class="text">0</p>
                  </li>
                  <li>
                    <p class="title_price">{{$t("vat_price")}}</p>
                    <!-- {{total_price.vat_price}} -->
                    <p class="text">0 {{$t("egp")}}</p>
                  </li>
                  <li>
                    <p class="title_price">{{$t("total")}}</p>
                    <!-- {{total_price.total}} -->
                    <p class="text">0 {{$t("egp")}}</p>
                  </li>
                </ul>
                <router-link class="btn checkout" to="/order">{{$t("checkout")}}</router-link>
              </div>
            </div>
        </div>
    </div>
  </section>
  
  <section v-else>
    <div class="row">
      <NotFound />
      <h3 class="not_found mb-4">{{$t("no_products_in_cart")}}</h3>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Cart from '@/components/include/cart/cart.vue';
import NotFound from '@/components/include/Lottifiles/NotFound.vue';
import { Bootstrap5Pagination } from 'laravel-vue-pagination';

export default {
  name: "cart-page",
  data() {
    return {
      auth:false,
      cart: {
        data: []
      },
      total_price: {},
      limit: 5
    }
  },
  components: {
    Cart,
    Bootstrap5Pagination,
    NotFound
  },
  methods: {
    fetchCart(page = 1) {
      axios
        .get(`/cart?page=${page}`)
        .then((response) => {
          this.cart = response.data.data;
          this.total_price = response.data.total_price;
          // console.log(response.data.total_price)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    async deleteAllCart() {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(delete_id);
          axios.delete(`/delete_all_cart`).then(({ data }) => {
            // console.log(result);
            if (data.status == true) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              this.fetchCart()
              // console.log(data);
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: data.message,
                showConfirmButton: true,
                timer: 6000,
              });
            }
            return data;
          });
        }
      });
      // const response = await axios.delete(`/delete_all_cart`);
      // Swal.fire({
      //   title: "",
      //   text: response.data.message,
      //   icon: "success",
      // });
      // this.fetchCart();
    },

    ScrollUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Add smooth scrolling behavior
      });
    },
  },
  created() {
    this.auth = this.$store.getters['isAuthenticated'];
    if(this.auth) {
      this.fetchCart()
    }
  }
};
</script>

<style></style>
