<template>
  <breadcrumb :title="title" :subTitle="subTitle" :link="link" />
  <category />
  <div class="container my-5">
    <div class="row">
      <div class="col-lg-3 col-12">
        <div class="filter">
          <!-- Start category filter -->
          <div class="category_filter">
            <p class="title">{{ $t("category") }}</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="categoryFilter"
                id="flexRadioDefault"
                :value="null"
                v-model="category_id"
                @change="productsData"
              />
              <label class="form-check-label" for="flexRadioDefault">
                {{$t("All Category")}}
              </label>
            </div>
            <div
              class="form-check"
              v-for="(category, index) in categories"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                name="categoryFilter"
                :id="'flexRadioDefault' + category.id"
                :value="category.id"
                v-model="category_id"
                @change="productsData"
              />
              <label
                class="form-check-label"
                :for="'flexRadioDefault' + category.id"
              >
                {{ category.title }}
              </label>
            </div>
          </div>
          <!-- End category filter -->

          <!-- Start brand filter -->
          <div class="brand_filter">
            <p class="title">{{ $t("brands") }}</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="brandsFilter"
                id="flexCheckboxDefault"
                v-model="brand_id"
                :value="null"
                @change="productsData"
              />
              <label class="form-check-label" for="flexCheckboxDefault">
                {{$t("All Brands")}}
              </label>
            </div>
            <div
              class="form-check"
              v-for="(brand, index) in brands"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                name="brandsFilter"
                :id="'flexCheckboxDefault' + brand.id"
                v-model="brand_id"
                :value="brand.id"
                @change="productsData"
              />
              <label
                class="form-check-label"
                :for="'flexCheckboxDefault' + brand.id"
              >
                {{ brand.title }}
              </label>
            </div>
          </div>
          <!-- End brand filter -->

          <!-- Start price filter -->
          <!-- <div class="price">
            <p class="title">{{ $t("price") }}</p>
            <div class="form-group">
              <input
                type="text"
                name=""
                id=""
                min="0"
                maxlength="2000000"
                @keyup="productsData"
                @input="filterInputNumber"
                v-model="from_price"
                class="form-control"
              />
              <span>{{$t("To")}}</span>
              <input
                type="text"
                name=""
                id=""
                :min="from_price"
                maxlength="1000000"
                @keyup="productsData"
                @input="filterInputToNumber"
                v-model="to_price"
                class="form-control"
              />
            </div>
          </div> -->
          <!-- End price filter -->

          <!-- Start discount filter -->
          <!-- <div class="discount_filter">
      <p class="title">{{ $t("discount_offers") }}</p>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="DiscountFilter"
          id="DiscountCheckbox1"
        />
        <label class="form-check-label" for="DiscountCheckbox1">
          Today's Deals
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="DiscountFilter"
          id="DiscountCheckbox2"
        />
        <label class="form-check-label" for="DiscountCheckbox2">
          Discount on Cameras
        </label>
      </div>
    </div> -->
          <!-- End discount filter -->

          <!-- Start availability filter -->
          <!-- <div class="availability_filter">
      <p class="title">{{ $t("availability") }}</p>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="AvailabilityFilter"
          value="1"
          id="Stock1"
        />
        <label class="form-check-label" for="Stock1">
          {{ $t("in_stock") }}
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="AvailabilityFilter"
          value="0"
          id="Stock2"
        />
        <label class="form-check-label" for="Stock2">
          {{ $t("out_stock") }}
        </label>
      </div>
    </div> -->
          <!-- End availability filter -->

          <!-- Start rating filter -->
          <div class="rating_filter">
            <p class="title">{{ $t("product_rating") }}</p>
            <!-- Five Rating -->
            <div class="form-check" >
              <input
                class="form-check-input"
                type="radio"
                name="ratingFilter"
                id="fiveRating"
                value="5"
                v-model="star_count"
                @change="productsData"
                hidden
              />
              <label class="form-check-label" for="fiveRating">
                <i class="fa-solid fa-star" v-for="(i, index) in 5" :key="index"></i>
                <span>& {{$t("up")}}</span>
              </label>
            </div>
            <!-- Five Rating -->

            <!-- Four Rating -->
            <div class="form-check" >
              <input
                class="form-check-input"
                type="radio"
                name="ratingFilter"
                id="fourRating"
                value="4"
                v-model="star_count"
                @change="productsData"
                hidden
              />
              <label class="form-check-label" for="fourRating">
                <i class="fa-solid fa-star" v-for="(i, index) in 4" :key="index"></i>
                <i class="fa-regular fa-star" v-for="(i, index) in 1" :key="index"></i>
                <span>& up</span>
              </label>
            </div>
            <!-- Four Rating -->

            <!-- Three Rating -->
            <div class="form-check" >
              <input
                class="form-check-input"
                type="radio"
                name="ratingFilter"
                id="threeRating"
                value="3"
                v-model="star_count"
                @change="productsData"
                hidden
              />
              <label class="form-check-label" for="threeRating">
                <i class="fa-solid fa-star" v-for="(i, index) in 3" :key="index"></i>
                <i class="fa-regular fa-star" v-for="(i, index) in 2" :key="index"></i>
                <span>& up</span>
              </label>
            </div>
            <!-- Three Rating -->


            <!-- Two Rating -->
            <div class="form-check" >
              <input
                class="form-check-input"
                type="radio"
                name="ratingFilter"
                id="twoRating"
                value="2"
                v-model="star_count"
                @change="productsData"
                hidden
              />
              <label class="form-check-label" for="twoRating">
                <i class="fa-solid fa-star" v-for="(i, index) in 2" :key="index"></i>
                <i class="fa-regular fa-star" v-for="(i, index) in 3" :key="index"></i>
                <span>& up</span>
              </label>
            </div>
            <!-- Two Rating -->

            <!-- One Rating -->
            <div class="form-check" >
              <input
                class="form-check-input"
                type="radio"
                name="ratingFilter"
                id="oneRating"
                value="1"
                v-model="star_count"
                @change="productsData"
                hidden
              />
              <label class="form-check-label" for="oneRating">
                <i class="fa-solid fa-star" v-for="(i, index) in 1" :key="index"></i>
                <i class="fa-regular fa-star" v-for="(i, index) in 4" :key="index"></i>
                <span>& up</span>
              </label>
            </div>
            <!-- One Rating -->
          </div>
          <!-- End rating filter -->

          <button type="button" class="btn PrimaryButton" @click="resetFilter()">{{$t("reset_filter")}}</button>
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <div class="row" v-if="products.data.length">
          <div class="sort">
            <h5>{{ $t("feature_products") }}</h5>
            <select name="" id="" class="form-control" v-model="sorted" @change="productsData">
              <option value="" selected hidden>{{$t("sort_by")}}:</option>
              <!-- <option value="price_low_to_high">{{$t("price_low_to_high")}}</option>
              <option value="price_high_to_low">{{$t("price_high_to_low")}}</option> -->
              <option value="new_arrival">{{$t("new_arrival")}}</option>
              <option value="top_rate">{{$t("top_rate")}}</option>
            </select>
          </div>
          <div
            class="col-lg-4 col-md-6 col-12 mb-4"
            v-for="(product, index) in products.data"
            :key="index"
          >
            <product :product="product" />
          </div>
        </div>
        
        <div class="row" v-else>
          <NotFound />
          <h3 class="not_found">{{$t("no_products")}}</h3>
        </div>

        <div class="row">
          <div class="col-12">
            <Bootstrap5Pagination
              :data="products"
              :limit="limit"
              class="mx-auto flex align-items-center justify-content-center pagination"
              @pagination-change-page="productsData"
              @click="ScrollUp"
            >
              <template v-slot:prev-nav>&lt;</template>
              <template v-slot:next-nav>&gt;</template>
            </Bootstrap5Pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import breadcrumb from "@/components/layouts/breadcrumb.vue";
import category from "@/components/include/index/category.vue";
// import Filter from "@/components/include/product/filter.vue";
import product from "@/components/include/product/product.vue";
// import { productsData } from "@/api/product/product.js";
import axios from "axios";
import { categoriesData } from "@/api/index/category.js";
import { brandsData } from "@/api/index/brands.js";
import NotFound from '@/components/include/Lottifiles/NotFound.vue';

export default {
  data() {
    return {
      products: {
        data: []
      },
      categories: [],
      brands: [],
      title: this.$t("products"),
      subTitle: "",
      link: "",
      value: "",
      category_id: "",
      brand_id: "",
      from_price: "",
      to_price: "",
      star_count: "",
      sorted: "",
      limit: 5
    };
  },
  components: {
    breadcrumb,
    category,
    Bootstrap5Pagination,
    product: product,
    NotFound
  },
  methods: {
    filterInputNumber() {
      this.from_price = this.from_price.replace(/\D/g, "");
    },
    filterInputToNumber() {
      this.to_price = this.to_price.replace(/\D/g, "");
    },
    productsData(page = 1) {
      const category_id = this.category_id == null ? "" : this.category_id;
      const brand_id = this.brand_id == null ? "" : this.brand_id;
      const star_count = this.star_count == null ? "" : this.star_count;
      const sorted = this.sorted == null ? "" : this.sorted
      const from_price = this.from_price;
      const to_price = this.to_price;
      axios
        .get(`/filter_products?page=${page}&category_id=${category_id}&brand_id=${brand_id}&from_price=${from_price}&to_price=${to_price}&rate=${star_count}&sorted=${sorted}`)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            this.products = data.data.data;
            // console.log(this.products.data)
          }
        });
    },
    ScrollUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Add smooth scrolling behavior
      });
    },
    resetFilter() {
      this.category_id = "",
      this.brand_id = "",
      this.from_price = "",
      this.to_price = "",
      this.sorted = "",
      this.star_count = "",
      this.productsData()
    }
  },
  mounted() {
    categoriesData().then((response) => {
      this.categories = response.data.data;
      //   console.log(response.data)
      return response;
    });
    brandsData().then((response) => {
      this.brands = response.data.data;
      // console.log(this.brands);
      return response;
    });
  },
  created() {
    this.productsData();
  },
};
</script>