<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="data_profile">
      <h2>{{$t("profile_info")}}</h2>
      <form v-on:submit.prevent="SubmitForm">
          <div class="form-group">
            <label for="">{{$t("name")}}</label>
            <input type="text" name="" id="" v-model="User.name" class="form-control" maxlength="100" :disabled="isNameDisabled" @input="filterInputName">
            <button class="btn iconEdit" type="button" @click="toggleNameInput">
              <i class="fa-regular fa-pen-to-square"></i>
            </button>
          </div>
          <div class="form-group">
            <label for="">{{$t("phone")}}</label>
            <vue-tel-input v-model="phone" class="form-control" v-bind="bindProps" :disabled="isPhoneDisabled"></vue-tel-input>
            <!-- <button class="btn iconEdit" type="button" @click="togglePhoneInput">
              <i class="fa-regular fa-pen-to-square" @click="togglePhoneInput"></i>
            </button> -->
          </div>
          <div class="form-group">
            <label for="">{{$t("email")}}</label>
            <input type="email" name="" id="" v-model="User.email" class="form-control" maxlength="100" :disabled="isEmailDisabled" @input="validateEmail">
            <span v-if="!isValidEmail" class="error-message text-danger">{{$t("Please enter a valid email address")}}</span>
            <!-- <button class="btn iconEdit" type="button" @click="toggleEmailInput">
              <i class="fa-regular fa-pen-to-square" @click="toggleEmailInput"></i>
            </button> -->
          </div>
          <button type="submit" class="btn submit_btn" :disabled="!isValidEmail">{{$t("save_changes")}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  data() {
    return {
      User: {
        name: JSON.parse(localStorage.getItem("user")).name,
        email: JSON.parse(localStorage.getItem("user")).email,
      },
      phone: "+"+JSON.parse(localStorage.getItem("user")).phone_code+JSON.parse(localStorage.getItem("user")).phone,
      bindProps: {
        mode: "international",
        // defaultCountry: "SA",
        autoDefaultCountry: false,
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          placeholder: this.$t("phone"),
        },
        validCharactersOnly: true
      },
      isValidEmail: true,
      isNameDisabled: true,
      isPhoneDisabled: true,
      isEmailDisabled: true,
    };
  },
  components: {
    VueTelInput,
  },
  methods: {
    filterInputName() {
      this.User.name = this.User.name.replace(/[^a-zA-Z\s]/g, '');
    },
    filterInputNumber() {
      this.User.phone = this.User.phone.replace(/\D/g, '');
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.User.email);
    },

    toggleNameInput() {
      this.isNameDisabled = !this.isNameDisabled;
    },
    togglePhoneInput() {
      this.isPhoneDisabled = !this.isPhoneDisabled;
    },
    toggleEmailInput() {
      this.isEmailDisabled = !this.isEmailDisabled;
    },

    SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin

      const formData = new FormData();
      Object.entries(this.User).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('phone_code', this.phone.split(' ')[0].replace('+', ''));
      formData.append('phone', this.phone.split(' ').slice(1).join(' ').replace(/\s/g, ''));
      formData.append("_method", "PUT");
      
      axios
        .post("/edit_profile", formData)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            
            // Retrieve the User object from local storage
            let storedUser = JSON.parse(localStorage.getItem('user'));
            
            // Update the this.User object as well
            storedUser.name = this.User.name;

            // Convert the updated object back into a JSON string
            let updatedData = JSON.stringify(storedUser);

            // Save the updated User object back to local storage
            localStorage.setItem('user', updatedData);


            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
          } else {
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: true,
            timer: 6000,
          });
          // this.$router.push({ path: "/teachers" });
          this.loading = false;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
};
</script>
<style>
  .breadcrumb {
    margin-bottom: 4rem;
  }
</style>