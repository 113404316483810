export default {
    setUser(state, { user }) {
      state.token = user.token;
      state.user = {
        name: user.name,
        phone: user.phone,
        phone_code: user.phone_code,
        email: user.email,
      };
  },logout(state) {
    state.token = null;
    state.user = null;
  }
};