<template>
  <div class="container">
    <h3 class="title_head title_head_want_product">{{$t ("want_product")}}</h3>
    <div class="want_product">
      <form v-on:submit.prevent="SubmitForm" class="form_want_product">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12 col-12">
          <h5>{{$t ("tell_us_product")}}</h5>
          <p>{{$t ("inform_all_information")}}</p>
          <div class="form-group">
            <Multiselect
              v-model="Form.category_id"
              :options="categories"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('select_category')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <!-- <select name="" id="" class="form-control" v-model="Form.category_id" required>
              <option value="" v-if="Form.category_id == ''" hidden selected disabled>{{$t("select_category")}}</option>
              <option :value="category.id" v-for="(category, index) in categories" :key="index">{{category.title}}</option>
            </select> -->
          </div>
          <div class="form-group">
            <input
              type="text"
              name=""
              id=""
              class="form-control"
              v-model="Form.name"
              :placeholder="$t('product_name')"
              maxlength="100"
              required
              ref="nameInput"
              @invalid="setCustomValidity"
              @input="clearCustomValidity"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              name=""
              id=""
              class="form-control"
              v-model="Form.email"
              :placeholder="$t('your_email')"
              maxlength="100"
              :disabled="auth"
              required
            />
          </div>
          <div class="form-group file">
            <label for="file" v-if="Form.FileName == null">
              <span>{{$t('attachment_file')}}</span>  
              <i class="fa-solid fa-paperclip"></i>
            </label>
            <label for="file" v-else>
              <span>{{ Form.FileName }}</span>
              <i class="fa-solid fa-paperclip"></i>
            </label>
            <input
              @change="handleFileChange"
              type="file"
              name=""
              ref="file"
              id="file"
              class="form-control"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              :placeholder="$t('attachment_file')"
              title="Attachment File"
            />
          </div>
          <div class="form-group">
            <textarea
              name=""
              class="form-control"
              cols="30"
              rows="5"
              v-model="Form.text"
              :placeholder="$t('describe_experience')"
              required
              :maxlength="maxTextLength"
            ></textarea>
            <span class="max_length">{{Form.text.length}} / {{maxTextLength - Form.text.length}}</span>
          </div>
          <button type="submit" class="btn submitBtn" :disabled="disableButton">{{$t ("send_request")}}</button>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <img src="../../../assets/media/want_product.jpg" alt="want_product">
        </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { categoriesData } from "@/api/index/category.js";
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      status: "",
      categories: [],
      Form: {
        category_id: "",
        name: "",
        email: this.$store.getters['isAuthenticated'] ? JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).email : "",
        text: "",
        FileName: null,
      },
      maxTextLength: 500,
      file : "",
      disableButton: false,
      auth: this.$store.getters['isAuthenticated']
    };
  },
  components: {
    Multiselect 
  },
  methods: {
    setCustomValidity() {
      this.$refs.nameInput.setCustomValidity('please fill out this field');
    },
    clearCustomValidity() {
      this.$refs.nameInput.setCustomValidity('');
    },
    handleFileChange(event) {
      // Call the first function
      this.fileName(event);
      
      // Call the second function and pass the event object
      this.uploadFile();
    },
    fileName(e) {
      this.Form.FileName = e.target.value;
    },
    uploadFile() {
      this.file = this.$refs.file.files[0];
    },
    SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.disableButton = true; //the disableButton begin

      const formData = new FormData();
      if (this.file != undefined && this.file != "") {
        formData.append('file', this.file);
      }
      formData.append('category_id', this.Form.category_id.id);
      formData.append('name', this.Form.name);
      formData.append('email', this.Form.email);
      formData.append('text', this.Form.text);

      axios
        .post("/want_product", formData)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            if (!this.auth) {
              this.Form.email = ""
            }
            this.file = null;
            this.Form.category_id = "";
            this.Form.name = "";
            this.Form.text = "";
            this.disableButton = false;
          } else {
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            this.disableButton = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: true,
            timer: 6000,
          });
          // this.$router.push({ path: "/teachers" });
          this.disableButton = false;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  mounted() {
    categoriesData().then((response) => {
      this.status = response.data.status;
      this.categories = response.data.data;
      return response;
    });
  },
  computed: {
    filesNames() {
      const fn = [];
      for (let i = 0; i < this.files.length; ++i) {
        fn.push(this.files.item(i).name);
      }
      return fn;
    },
  },
};
</script>
