<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="data_profile">
      <form v-on:submit.prevent="SubmitForm" class="pawword_auth">
        <div class="form-group">
          <label for="">{{ $t("Current Password") }}</label>
          <input
            :type="passwordFieldTypeCurrent"
            name=""
            id=""
            class="form-control"
            :placeholder="$t('Current Password')"
            v-model="Form.current_password"
            maxlength="30"
          />
          <button
            class="btn show_password"
            type="button"
            @click="switchVisibilityCurrent"
            v-html="eyeCurrent"
          ></button>
        </div>
        <div class="form-group d-block">
          <div class="form-group mb-2">
            <label for="">{{ $t("New Password") }}</label>
            <input
              :type="passwordFieldTypeNew"
              name=""
              id=""
              class="form-control"
              :placeholder="$t('New Password')"
              v-model="Form.password"
              maxlength="30"
              @input="validatePassword"
            />
            <button
              class="btn show_password"
              type="button"
              @click="switchVisibilityNew"
              v-html="eyeNew"
            ></button>
          </div>
          <!-- password validation message -->
          <div v-if="!isPasswordValid" class="error-message text-danger">
            {{$t("Password must contain at least one special character, one alphabet, one number, and be at least 8 characters long")}}
          </div>
          <!-- password validation message -->
        </div>
        <div class="form-group">
          <label for="">{{ $t("Confirm Password") }}</label>
          <input
            :type="passwordFieldTypeConfirm"
            name=""
            id=""
            class="form-control"
            :placeholder="$t('Confirm Password')"
            v-model="Form.password_confirmation"
            maxlength="30"
          />
          <button
            class="btn show_password"
            type="button"
            @click="switchVisibilityConfirm"
            v-html="eyeConfirm"
          ></button>
        </div>
        <button type="submit" class="btn submit_btn">
          {{ $t("change_password") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isPasswordValid: true,
      Form: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      passwordFieldTypeNew: "password",
      passwordFieldTypeCurrent: "password",
      passwordFieldTypeConfirm: "password",
      eyeNew: "<i class='fa-solid fa-eye'></i>",
      eyeCurrent: "<i class='fa-solid fa-eye'></i>",
      eyeConfirm: "<i class='fa-solid fa-eye'></i>",
    };
  },
  methods: {
    validatePassword() {
      const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      this.isPasswordValid = regex.test(this.Form.password);
    },

    switchVisibilityNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
      this.eyeNew =
        this.eyeNew == "<i class='fa-solid fa-eye'></i>"
          ? "<i class='fa-solid fa-eye-slash'></i>"
          : "<i class='fa-solid fa-eye'></i>";
    },
    switchVisibilityCurrent() {
      this.passwordFieldTypeCurrent =
        this.passwordFieldTypeCurrent === "password" ? "text" : "password";
      this.eyeCurrent =
        this.eyeCurrent == "<i class='fa-solid fa-eye'></i>"
          ? "<i class='fa-solid fa-eye-slash'></i>"
          : "<i class='fa-solid fa-eye'></i>";
    },
    switchVisibilityConfirm() {
      this.passwordFieldTypeConfirm =
        this.passwordFieldTypeConfirm === "password" ? "text" : "password";
      this.eyeConfirm =
        this.eyeConfirm == "<i class='fa-solid fa-eye'></i>"
          ? "<i class='fa-solid fa-eye-slash'></i>"
          : "<i class='fa-solid fa-eye'></i>";
    },

    SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin

      const formData = new FormData();
      Object.entries(this.Form).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("_method", "PUT");

      if (!this.isPasswordValid) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Password must contain at least one special character, one alphabet, one number, and be at least 8 characters long"),
        });
      } else if (this.Form.password === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("password field is empty"),
        });
      } else if (this.Form.password_confirmation === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("password confirmation field is empty"),
        });
      } else if (this.Form.password != this.Form.password_confirmation) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("check_password_same"),
        });
      }
      else {   
        axios
          .post("/edit_password", formData)
          .then((data) => {
            // console.log(data);
            if (data.data.status == true) {
              //Perform Success Action
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              this.Form = {
                current_password: "",
                password: "",
                password_confirmation: "",
              };
            } else {
              //Perform Success Action
              Swal.fire({
                type: "error",
                position: "center",
                icon: "error",
                title: data.data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              this.loading = false;
            }
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: error.response.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            // this.$router.push({ path: "/teachers" });
            this.loading = false;
          })
          .finally(() => {
            //Perform action in always
          });
      }

    },
  },
};
</script>

<style>
.breadcrumb {
  margin-bottom: 4rem;
}
</style>
