<template>
  <section class="track_order_details">
    <div class="container">
        <div class="progress-container" v-if="order.status == 'pending' || order.status == 'preparing' || order.status == 'out_for_delivery' || order.status == 'delivered' || order.status == 'finished'">
            <ul class="progress-steps">
                <li :class="{ active: order.status == 'pending' || order.status == 'preparing' || order.status == 'out_for_delivery' || order.status == 'delivered' || order.status == 'finished'}">{{$t("pending")}}</li>
                <li :class="{ active: order.status == 'preparing' || order.status == 'out_for_delivery' || order.status == 'delivered' || order.status == 'finished'}">{{$t("Product Prepared")}}</li>
                <li :class="{ active: order.status == 'out_for_delivery' || order.status == 'delivered' || order.status == 'finished'}">{{$t("Out For Delivery")}}</li>
                <li :class="{ active: order.status == 'delivered' || order.status == 'finished'}">{{$t("Delivered")}}</li>
                <li :class="{ active: order.status == 'finished'}">{{$t("Finished")}}</li>
            </ul>
        </div>

        <div class="cancel_order" v-if="order.status == 'client_cancel' || order.status == 'provider_cancel' || order.status == 'provider_reject'">
            <img src="../assets/media/icon/close.png" alt="cancel">
            <h4 class="title">{{order.status == "client_cancel" ? $t("client_cancel") : order.status == "provider_cancel" ? $t("provider_cancel") : order.status == "provider_reject" ? $t("provider_reject") : ""}}</h4>
        </div>

        <div class="row">
            <div class="col-lg-9 col-md-8 col-12">
                <ul class="order_details">
                    <li>
                        <p class="title"><i class="fa-solid fa-box"></i>{{$t("Order Number")}} : </p>
                        <p class="text"> {{order.order_number}}</p>
                    </li>
                    <li>
                        <p class="title"><i class="fa-solid fa-calendar-day"></i>{{$t("Estimated Time")}} : </p>
                        <p class="text"> {{order.estimated_time}}</p>
                    </li>
                    <li>
                        <p class="title"><i class="fa-regular fa-images"></i>{{$t("Shipping Address")}} : </p>
                        <p class="text"> {{order.address.title}} - {{order.address.city.title}} - {{order.address.city.country.title}}</p>
                    </li>
                </ul>
            </div>
            <!-- <div class="col-lg-3 col-md-4 col-12">
                <div class="cart_summary">
                    <h6 class="title">{{$t("cart_summary")}}</h6>
                    <div class="products_cart" v-for="(product, index) in order.items" :key="index">
                        <img :src="product.product.image" :alt="product.product.title">
                        <div class="details">
                            <p class="title">{{product.product.title}}</p>
                            <p class="price">{{product.product.price}} {{$t("egp")}}</p>
                            <p class="quantity">{{$t("quantity")}} : {{product.quantity}}</p>
                        </div>
                    </div>
                    <div class="total">
                        <p class="title">{{$t("total")}} : </p>
                        <p class="text">{{order.total_price}} {{$t("egp")}}</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: "track-details",
    data() {
        return {
            order: {
                address: {
                    city: {
                        country: {}
                    }
                }
            }
        }
    },
    methods: {
        trackOrder() {
            const track_number = this.$route.params.id
            axios
                .get(`/orders/${track_number}`)
                .then((data) => {
                    // console.log(data);
                    if (data.data.status == "success") {
                        this.order = data.data.data;
                        // console.log(this.order)
                    } else {
                        //Perform Success Action
                        Swal.fire({
                            type: "error",
                            position: "center",
                            icon: "error",
                            title: data.data.message,
                            showConfirmButton: true,
                            timer: 6000,
                        });
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        type: "error",
                        position: "center",
                        icon: "error",
                        title: error.response.data.message,
                        showConfirmButton: true,
                        timer: 6000,
                    });
                    // this.$router.push({ path: "/teachers" });
                    this.loading = false;
                })
                .finally(() => {
                    //Perform action in always
                });
        }
    },
    created() {
        this.trackOrder()
    }
}
</script>

<style>

</style>