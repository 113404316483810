<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="wishlist_profile">
      <h2>{{$t("my_wishlist")}} ({{wishlists.meta.total}})</h2>
      <WishlistCard v-if="wishlists.data.length" :wishlists="wishlists.data" :wishlistData="wishlistData" />
      <div class="row" v-else>
        <NotFound />
        <h3 class="not_found mb-4">{{$t("no_products_in_wishlist")}}</h3>
      </div>

      <div class="row mt-3">
        <Bootstrap5Pagination
          :data="wishlists"
          :limit="limit"
          class="mx-auto flex align-items-center justify-content-center pagination"
          @pagination-change-page="wishlistData"
          @click="ScrollUp"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </Bootstrap5Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import WishlistCard from "@/components/include/profile/wishlist/wishlist_card.vue";
import axios from 'axios';
import Swal from 'sweetalert2';
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import NotFound from '@/components/include/Lottifiles/NotFound.vue';
// import { wishlistData } from "@/api/profile/wishlist.js";

export default {
  data() {
    return {
      wishlists: {
        data: []
      },
      limit: 5
    };
  },
  components: {
    Bootstrap5Pagination,
    WishlistCard,
    NotFound
  },
  methods: {
    wishlistData(page = 1) {
      axios
        .get(`/favourite_products?page=${page}`)
        .then((response) => {
          this.wishlists = response.data.data;
          // console.log(this.wishlists)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    ScrollUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Add smooth scrolling behavior
      });
    },
  },
  created() {
    this.wishlistData();
  },
}
</script>

<style>
  .breadcrumb {
    margin-bottom: 4rem;
  }
</style>