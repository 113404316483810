<template>
  <div
    class="notification_card"
    v-for="(notification, index) in notifications"
    :key="index"
  >
    <img
      v-if="notification.image != ''"
      src="@/assets/media/want_product.jpg"
      :alt="notification.title"
    />
    <img
      v-if="notification.image == ''"
      src="@/assets/media/logo.png"
      class="image_default"
      :alt="notification.title"
    />
    <p class="title">{{ notification.title }}</p>
    <div class="date_time">
      <span class="date">{{ notification.date }}</span>
      <span class="time">{{ notification.time }}</span>
    </div>
  </div>
</template>

<script>
export default {
    props: ["notifications"]
};
</script>

<style>
</style>