<template>
  <div class="related_products p-0" v-if="related_products.length">
    <h2>{{ $t("related_products") }}</h2>
      <!-- :loop="true" -->
    <swiper
      :slidesPerView="4"
      :spaceBetween="30"
      :slidesPerGroup="4"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }"
      :loopFillGroupWithBlank="true"
      :navigation="true"
      :modules="modules"
      :breakpoints="{
        300: {
          slidesPerView: 1,
          // slidesPerGroup: 1,
          loopFillGroupWithBlank: false,
        },
        768: {
          slidesPerView: 2,
          loopFillGroupWithBlank: false,
        },
        1024: {
          slidesPerView: 3,
          loopFillGroupWithBlank: true,
        },
        1440: {
          slidesPerView: 4,
          loopFillGroupWithBlank: true,
        },
      }"
      class="mySwiper"
    >
      <swiper-slide v-for="(product, index) in related_products" :key="index">
        <product :product="product" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import product from "@/components/include/product/product.vue";
import { relatedProductData } from "@/api/product/related_product.js";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import Swiper core and required modules
import SwiperCore, { Autoplay, FreeMode, Navigation, Thumbs } from "swiper";

// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs]);

SwiperCore.use([FreeMode, Navigation, Thumbs]);

import { ref } from "vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    product: product,
  },
  data() {
    return {
      thumbsSwiper: null,
      related_products: [],
    };
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  setup() {
    const visibleRef = ref(false);
    const indexRef = ref(0); // default 0
    const imgsRef = ref([]);
    // Img Url , string or Array of string
    // ImgObj { src: '', title: '', alt: '' }
    // 'src' is required
    // allow mixing
    const onShow = () => {
      visibleRef.value = true;
    };
    const showMultiple = () => {
      imgsRef.value = [];
      indexRef.value = 0; // index of imgList
      onShow();
    };
    const onHide = () => (visibleRef.value = false);
    return {
      visibleRef,
      indexRef,
      imgsRef,
      showMultiple,
      onHide,
      modules: [Autoplay, Navigation],
    };
  },
  created() {
    let id = this.$route.params.id;
    relatedProductData(id).then((response) => {
      this.related_products = response.data.data;
      // console.log(this.related_products);
      return response;
    });
  },
};
</script>

<style></style>
