<template>
  <breadcrumb :title="title" :subTitle="subTitle" :link="link" />
  <div class="container">
    <div class="row">
        <Sidebar />
        <Notification />
    </div>
  </div>
</template>

<script>
import breadcrumb from "@/components/layouts/breadcrumb.vue";
import Sidebar from '@/components/layouts/sidebar.vue';
import Notification from '@/components/include/profile/notication/notification.vue';
export default {
  data() {
    return {
      title: this.$t("notification"),
      subTitle: "",
      link: "",
    };
  },
  components: { breadcrumb, Sidebar, Notification },
};
</script>

<style>
</style>