<template>
  <div
    class="wishlist_card"
    v-for="(wishlist, index) in wishlists"
    :key="index"
  >
    <router-link :to="`/product/${wishlist.id}`" class="data_wishlist">
      <img :src="wishlist.image" :alt="wishlist.title" />
      <p class="title">{{ wishlist.title }}</p>
      <p class="price">{{ wishlist.price }} {{ $t("egp") }}</p>
      <p class="price" :class="`${wishlist.amount == 0 ? 'out_stock' : 'in_stock'}`">{{ wishlist.amount == 0 ? $t("out_stock") : $t("in_stock") }}</p>
    </router-link>
    <div class="actions">
      <button type="button" class="btn add_to_cart_btn" @click="addToCart(wishlist.id)" :disabled="wishlist.amount == 0">{{ $t("add_to_cart") }}</button>
      <button type="button" class="btn remove_btn" @click="RemoveWishList(wishlist.id)">{{ $t("remove") }}</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  props: {
    wishlists: Array,
    wishlistData: Object
  },
  methods: {
    RemoveWishList(id) {
      axios.delete(`/favourite_products/${id}`).then(({ data }) => {
        this.status = data.status;
        if (this.status == true) {
          this.wishlistData()
        }
        else {
          // console.log("error")
        }
        // console.log(this.status);
      });
    },
    addToCart(product_id) {
      const formData = new FormData();
      formData.append("product_id", product_id);
      formData.append("quantity", 1);
      axios
        .post("/cart", formData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/cart");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
};
</script>

<style></style>
