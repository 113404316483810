<template>
  <section class="order">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-12 col-12">
          <FormWizard
            @on-complete="SubmitForm"
            back-button-text="Back"
            next-button-text="Next"
            finish-button-text="Submit"
            color="#30475E"
            shape="tab"
            class="form"
          >
            <TabContent :title="$t('Shipping Address')" :before-change="FirstStep">
              <div class="first_step_order">
                <h4 class="title">{{$t("Select Delivery Address")}}</h4>
                <div class="form-check" v-for="(address, index) in addresses" :key="index">
                  <input class="form-check-input" type="radio" name="myAddress" :value="address.id" v-model="address_id" :id="`myAddress${index}`" :checked="addresses.length == 1">
                  <label class="form-check-label" :for="`myAddress${index}`">
                    <ul class="user_data">
                      <li>
                        <p class="title">{{$t("name")}}: </p>
                        <p class="text">{{address.user.name}}</p>
                      </li>
                      <li>
                        <p class="title">{{$t("phone")}}: </p>
                        <p class="text">{{address.user.phone}}</p>
                      </li>
                      <li>
                        <p class="title">{{$t("address")}}: </p>
                        <p class="text">{{address.title.substring(0, 30) + ' ...'}} - {{address.city.title.substring(0, 30) + ' ...'}} - {{address.city.country.title.substring(0, 30) + ' ...'}}</p>
                      </li>
                    </ul>
                  </label>
                </div>
                <!-- Button trigger modal -->
                <button class="btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#addressModal">{{$t("Add New Address")}}</button>

                <!-- Modal -->
                <div class="modal fade" id="addressModal" tabindex="-1" aria-labelledby="addressModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <h4 class="title mb-0">{{$t("New Address")}}</h4>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form v-on:submit.prevent="addNewAddress" class="data_profile w-100 px-0">
                          <div class="row align-items-center">
                            <div class="form-group">
                              <label for="">{{ $t("country") }}</label>
                              <select
                                class="form-control"
                                v-model="newAddresses[0].country_id"
                                @change="getcities($event)"
                              >
                                <option
                                  :value="country.id"
                                  v-for="(country, index) in countries"
                                  :key="index"
                                >
                                  {{ country.title }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label for="">{{ $t("city") }}</label>
                              <select class="form-control" v-model="newAddresses[0].city_id">
                                <option
                                  :value="city.id"
                                  v-for="(city, index) in cities"
                                  :key="index"
                                >
                                  {{ city.title }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label for="">{{ $t("address") }}</label>
                              <input
                                type="text"
                                name=""
                                id=""
                                v-model="newAddresses[0].title"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <button type="submit" class="btn submit_btn">
                            {{ $t("save_changes") }}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabContent>
            <TabContent :title="$t('Payment Method')" :before-change="SecondStep">
              <div class="second_step_order">
                <h4 class="title">{{$t("Payment Method")}}</h4>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="cash" v-model="pay_type" name="payment" id="cash">
                  <label class="form-check-label" for="cash">
                    <i class="fa-solid fa-wallet"></i> {{$t("Cash")}}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="card" v-model="pay_type" name="payment" id="card">
                  <label class="form-check-label" for="card">
                    <i class="fa-regular fa-credit-card"></i> {{$t("Online Payment")}}
                  </label>
                </div>
              </div>
            </TabContent>
            <TabContent :title="$t('Confirm Order')">
              <div class="third_step_order">
                <h4 class="title">{{$t("Confirm Order")}}</h4>
                <cartSummary />
              </div>
            </TabContent>
          </FormWizard>
        </div>
        <div class="col-lg-3 col-md-12 col-12">
          <cartSummary />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";
import cartSummary from '@/components/include/order/cartSummary.vue';
import { countriesData } from "@/api/profile/address/countries.js";

export default {
  data() {
    return {
      addresses: [],
      newAddresses: [
        {
          country_id: "",
          city_id: "",
          title: ""
        }
      ],
      address_id: "",
      pay_type: "",
      countries: [],
      cities: [],
    }
  },
  //component code
  components: {
    FormWizard,
    TabContent,
    cartSummary
  },
  methods: {
    // get city by country
    getcities(e) {
      let country_id = e.target.value;
      axios.get(`/cities?country_id=${country_id}`).then(({ data }) => {
        for (let index = 0; index < this.countries.length; index++) {
          this.cities = data.data;
        }
      });
    },
    fetchAddress() {
      axios
        .get("/addresses")
        .then((response) => {
          this.addresses = response.data.data;
          // console.log(this.addresses)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    FirstStep() {
      if (this.address_id == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select address",
          showConfirmButton: true,
        });
        return false;
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Add smooth scrolling behavior
        });
        return true;
      }
    },
    SecondStep() {
      if (this.pay_type == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select payment methods",
          showConfirmButton: true,
        });
        return false;
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Add smooth scrolling behavior
        });
        return true;
      }
    },
    addNewAddress() {
      const formData = new FormData();
      for (let index = 0; index < this.newAddresses.length; index++) {
        formData.append(`address[${index}][country_id]`, this.newAddresses[index].country_id);
        formData.append(`address[${index}][city_id]`, this.newAddresses[index].city_id);
        formData.append(`address[${index}][title]`, this.newAddresses[index].title);
      }
      axios
        .post("/add_address", formData)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
              this.newAddresses[0].country_id = "";
              this.newAddresses[0].city_id = "";
              this.newAddresses[0].title = "";
            
            this.fetchAddress()
          } else {
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: true,
            timer: 6000,
          });
          // this.$router.push({ path: "/teachers" });
          this.loading = false;
        })
        .finally(() => {
          //Perform action in always
        });
    },
    SubmitForm() {
      let order = {
        address_id: this.address_id,
        pay_type: this.pay_type,
      }
      axios
        .post("/orders", order)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/my_order")
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetchAddress();
    countriesData().then((response) => {
      this.countries = response.data.data;
      // console.log(this.wishlists);
      return response;
    });
  }
};
</script>
<style></style>
