<template>
  <lottie-player
    src="https://assets8.lottiefiles.com/packages/lf20_a3kesdek.json"
    background="transparent"
    speed="1"
    loop
    autoplay
  ></lottie-player>
</template>

<script>
export default {};
</script>

<style>
    lottie-player {
        width: 600px;
        height: 600px;
        margin: 0 auto;
    }
</style>
