<template>
  <div class="card_seller">
    <img :src="seller.image" :alt="seller.title">
    <div class="description_seller">
      <h4>{{seller.title}}</h4>
      <button @click="addToCart(seller.id)" class="btn">{{$t("add_to_cart")}}</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  props: ["seller"],
  data() {
    return {
      auth: this.$store.getters['isAuthenticated']
    }
  },
  methods: {
    addToCart(product_id) {
      const formData = new FormData();
      formData.append("product_id", product_id);
      formData.append("quantity", 1);
      if(this.auth) {
        axios
          .post("/cart", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/cart");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
      else if (!this.auth) {
        Swal.fire({
          type: "error",
          position: "center",
          icon: "warning",
          title: this.$t("please_login"),
          showConfirmButton: true,
          timer: 2500,
        });
      }
    },
  }
}
</script>

<style>

</style>