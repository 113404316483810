<template>
  <Header />
  <category />
  <offer />
  <product />
  <seller />
  <brands />
  <want />
</template>

<script>
import Header from "@/components/include/index/header.vue";
import category from "@/components/include/index/category.vue";
import offer from "@/components/include/index/offer.vue";
import product from "@/components/include/index/product.vue";
import seller from "@/components/include/index/seller.vue";
import brands from "@/components/include/index/brands.vue";
import want from "@/components/include/index/want_product.vue";

export default {
  components: {
    Header,
    category,
    offer,
    product,
    seller,
    brands,
    want,
  },
};
</script>