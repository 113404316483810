<template>
    <!-- Modal -->
    <div class="modal modalShowImage fade" v-for="(img, index) in images" :key="index" :id="`imageModal${index}`" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="head_gallery">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <p class="length">{{images.length}} / {{index+1}}</p>
                    </div>
                    <div class="image">
                        <i class="fa-solid fa-chevron-right" data-bs-toggle="modal" v-if="images.length > 1" :data-bs-target="`#imageModal${index+1}`" :class="{disable:index+1 == images.length}"></i>
                        <img :src="images[index].image" :alt="index">
                        <i class="fa-solid fa-chevron-left" data-bs-toggle="modal" v-if="images.length > 1" :data-bs-target="`#imageModal${index-1}`" :class="{disable:index+1 == 1}"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "images-product",
    props: {
        images: Array,
    },
}
</script>

<style>

</style>