<template>
  <div class="container">
    <form class="auth" @submit.prevent="login">
        <h2>{{$t("Log Into My Account")}}</h2>
        <span class="or">{{$t("or")}}</span>
        <div class="form-group">
            <vue-tel-input v-model="phone" class="form-control" v-bind="bindProps"></vue-tel-input>
            <!-- <input type="number" name="" id="" class="form-control" :placeholder="$t('phone')" v-model="form.phone"> -->
        </div>
        <div class="form-group">
          <input :type="passwordFieldType" name="" id="" class="form-control" :placeholder="$t('password')" maxlength="30" v-model="form.password">
          <button class="btn show_password" type="button" @click="switchVisibility" v-html="eye"></button>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="rememberMe" id="rememberMe">
                <label class="form-check-label" for="rememberMe">
                    {{$t("Remember me")}}
                </label>
            </div>
            <router-link class="forget_password_btn" to="/forget_password">{{$t("Forgot your password ?")}}</router-link>
        </div>
        <button type="submit" class="btn submit_btn" :disabled="disableButton">{{$t("Log in")}}</button>
        <div class="register_redirect">{{$t("Don't have an account yet ?")}} <router-link to="/register">{{$t("Create Account")}}</router-link></div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            form: {
                password: "",
            },
            phone: "",
            passwordFieldType: "password",
            eye: "<i class='fa-solid fa-eye'></i>",
            bindProps: {
                mode: "international",
                defaultCountry: "EG",
                valid: true,
                inputOptions: {
                maxlength: 25,
                type: "tel",
                placeholder: this.$t("phone"),
                },
                validCharactersOnly: true
            },
            rememberMe: false,
            disableButton: false,
        };
    },
    components: {
        VueTelInput,
    },
    methods: {
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.eye = this.eye == "<i class='fa-solid fa-eye'></i>" ? "<i class='fa-solid fa-eye-slash'></i>" : "<i class='fa-solid fa-eye'></i>"
        },
        async login() {
            this.disableButton = true; //the disableButton begin
            const formData = new FormData();
            Object.entries(this.form).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append('phone_code', this.phone.split(' ')[0].replace('+', ''));
            formData.append('phone', this.phone.split(' ').slice(1).join(' ').replace(/\s/g, ''));
            let response = await this.$store.dispatch("Login", formData);
            // console.log(this.phone.replace(/\s+/g, ''));
            if (this.phone === "" || this.form.password === "") {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("Please dictate all fields"),
                });
                this.disableButton = false;
            }
            else {
                try {
                    if (response.data.status == true) {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: response.data.message,
                        });
                        this.form = {
                            password: "",
                        };
                        this.phone= "",
                        this.$router.go('/');
                        this.error = null;
                        this.disableButton = false;
                    }
                    else if(response.data.status == false){
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: response.data.message,
                        });
                        this.disableButton = false;
                    }
                }catch (err) {
                    // console.log(err.response)
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: err.response.data.message,
                    });
                    this.disableButton = false;
                }
            }
            if (this.rememberMe) {
                Cookies.set('rememberedUser', this.phone, { expires: 7 });
            }
        },
    },
}
</script>

<style>
    
</style>