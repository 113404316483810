<template>
  <NotFound />
</template>

<script>
import NotFound from '@/components/include/Lottifiles/NotFound.vue'

export default {
    name: "error-page",
    components: {
        NotFound
    }
}
</script>

<style>

</style>