<template>
  <section class="forget_password">
    <div class="container">
      <form class="auth" @submit.prevent="SubmitForm">
        <h2>{{ $t("Verification Required") }}</h2>
        <p class="text">
          {{$t("One Time Password (OTP) Sent To")}}
          +{{form.phone_code}}{{form.phone}}
          {{$t("Please Enter It Below")}}
        </p>
        <div class="form-group">
          <input
            type="number"
            name=""
            id=""
            class="form-control"
            :placeholder="$t('Enter OTP')"
            v-model="form.code"
          />
        </div>
        <button type="submit" class="btn submit_btn">
          {{ $t("create your account") }}
        </button>
        <p class="btn resent_otp" @click="resendOtp">{{$t("resend otp")}}</p>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  data() {
    return {
      form: {
        phone: JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).phone,
        phone_code: JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).phone_code,
        code: ""
      },
      userData: JSON.parse(localStorage.getItem("user"))
    };
  },
  methods: {
    async resendOtp() {
      try {
          const formData = {
            phone: JSON.parse(localStorage.getItem("user")).phone,
            phone_code: JSON.parse(localStorage.getItem("user")).phone_code
          };
          const response = await axios.post(`/send_code`, formData);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (error) {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        } finally {
          
        }
    },
    async SubmitForm() {
      if (this.form.code === "" ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("please enter verfication code"),
        });
        } else {
        try {
          let response = await this.$store.dispatch("Verify", this.form);
          // console.log(response);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.Form = {
              phone: "",
              email: "",
              name: "",
              password: "",
              password_confirmation: "",
            };
            this.$router.go('/');
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (err) {
          // console.log(err.response.data.message)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
          });
        }
      }
    },
  },
  created() {
    if(this.userData == null) {
      this.$router.push("/register")
    }
  }
};
</script>

<style scoped>
.auth h2 {
  text-align: start;
}
.auth .text {
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
}
.auth .submit_btn {
  margin-top: 0;
}
</style>
