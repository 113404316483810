<template>
  <div class="col-lg-3 col-md-4 col-12">
    <div class="sidebar">
        <router-link to="/profile">{{$t("profile_info")}}</router-link>
        <router-link to="/address">{{$t("address")}}</router-link>
        <router-link to="/password">{{$t("change_password")}}</router-link>
        <!-- <router-link to="/notification">{{$t("notification")}}</router-link> -->
        <router-link to="/my_order">{{$t("my_order")}}</router-link>
        <router-link to="/wishlist">{{$t("my_wishlist")}}</router-link>
        <router-link to="/reviews">{{$t("my_product_reviews")}}</router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>