<template>
  <div class="brands" v-if="status == true && brands.length">
    <div class="container">
      <h3 class="title_head">{{$t ("brands")}}</h3>
      <swiper
        :slidesPerView="4"
        :spaceBetween="30"
        :slidesPerGroup="4"
        :loopFillGroupWithBlank="true"
        :modules="modules"
        :pagination="{
          clickable: true,
        }"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          300: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: 4,
            loopFillGroupWithBlank: true,
          },
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(brand, index) in brands" :key="index">
          <div class="logo_brand">
            <img
              :src="brand.image"
              alt="product"
              class="image_product"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>


<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay, Pagination } from "swiper";

import "swiper/css/pagination";

import { brandsData } from "@/api/index/brands.js";

export default {
  data() {
    return {
      status: "",
      brands: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
  mounted() {
    brandsData().then((response) => {
      this.status = response.data.status;
      this.brands = response.data.data.reverse(); // Reversing the brands array;
      return response;
    });
  },
};
</script>
