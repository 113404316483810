<template>
  <!-- start footer section -->
  <footer>
    <div class="container">
      <div class="row">
        <div
          class="
            col-lg-4 col-md-12 col-sm-12
            logo-col
            d-flex
            flex-column
            align-items-sm-center align-items-lg-start
            justify-content-sm-start
            contact
          "
        >
          <img src="../../assets/media/logo.png" alt="logo footer" />
          <p v-if="lang == 'en'">{{lang == 'ar' ? contact_data.address_ar : contact_data.address_en}}</p>
          <ul class="mobile">
            <li><a :href="`tel:${contact_data.phone}`">{{contact_data.phone}}</a></li>
          </ul>
          <socialMedia @dataLoaded="handleDataLoaded" />
        </div>
        <div class="d-lg-flex col-lg-8 col-md-12 col-sm-12 mx-auto row">
          <div class="col-lg-4 col-md-4 col-6 col-links">
            <div class="h3 d-block">{{$t("shop")}}</div>
            <div class="d-lg-block">
              <ul>
                <li>
                  <a href="/#Features" class="hvr-float-shadow"
                    >{{$t("features")}}</a
                  >
                </li>
                <li>
                  <a href="/#best_seller" class="hvr-float-shadow"
                    >{{$t("best_seller")}}</a
                  >
                </li>
                <li>
                  <a href="/#offers" class="hvr-float-shadow"
                    >{{$t("offers")}}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-6 col-links">
            <div class="h3 d-block">{{$t ("fast_links")}}</div>
            <div class="d-lg-block">
              <ul>
                <li>
                  <router-link to="/products" class="hvr-float-shadow"
                    >{{$t ("products")}}</router-link
                  >
                </li>
                <li>
                  <router-link to="/about" class="hvr-float-shadow"
                    >{{$t ("about_us")}}</router-link
                  >
                </li>
                <li>
                  <router-link to="/contact" class="hvr-float-shadow"
                    >{{$t ("contact_us")}}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-6 col-links">
            <div class="h3 d-block">{{$t ("information")}}</div>
            <div class="d-lg-block">
              <ul>
                <li>
                  <router-link to="/faq" class="hvr-float-shadow"
                    >{{$t ("faq")}}</router-link
                  >
                </li>
                <li>
                  <router-link to="/terms" class="hvr-float-shadow"
                    >{{$t ("terms")}}</router-link
                  >
                </li>
                <li>
                  <router-link to="/privacy" class="hvr-float-shadow"
                    >{{$t ("privacy")}}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="item col-sm-12">
              <p>Copyright © 2022 ELSAYGH - All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer section -->
</template>

<script>
import socialMedia from '@/components/include/contact/social.vue';

export default {
  data() {
    return {
      lang: "",
      contact_data: {}
    };
  },
  components: {
    socialMedia
  },
  methods: {
    handleDataLoaded(data) {
      this.contact_data = data;
      // console.log("Data received from child:", this.socialData);
    },
  },
  created() {
    this.lang = localStorage.getItem("lang");
  }
};
</script>