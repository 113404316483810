<template>
  <section class="faq">
    <div class="container">
        <h2 class="title">{{$t("faq")}}</h2>
        <div class="accordion" id="accordionFaq">
            <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
                <h2 class="accordion-header" :id="'heading'+index">
                    <button :class="`accordion-button ${index == 0 ? '' : 'collapsed'}`" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" aria-expanded="true" :aria-controls="`collapse${index}`">
                        <p>{{faq.question}}</p>
                    </button>
                </h2>
                <div :id="`collapse${index}`" :class="`accordion-collapse collapse ${index == 0 ? 'show' : ''}`" :aria-labelledby="`heading${index}`" data-bs-parent="#accordionFaq">
                    <div class="accordion-body">
                        <p class="text" v-html="faq.answer"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>


<script>
import { faqData } from "@/api/static/faq.js";

export default {
  data() {
    return {
      faqs: [],
    };
  },
  mounted() {
    faqData().then((response) => {
      this.faqs = response.data.data;
      // console.log(response.data);
      return response;
    });
  },
};
</script>
