<template>
  <div class="product_card">
    <div class="actions">
      <div class="form-check heart">
        <img
          src="@/assets/media/icon/heart.svg"
          v-if="product.is_favourite == 0"
          :class="`${product.is_favourite == 0 ? '' : 'favourite'}`"
          alt="heart"
          @click="AddWishList(product.id)"
        />
        <img
          src="@/assets/media/icon/favourite.svg"
          v-else-if="product.is_favourite == 1"
          :class="`${product.is_favourite == 0 ? '' : 'favourite'}`"
          alt="heart"
          @click="RemoveWishList(product.id)"
        />
      </div>
      <div class="compare">
        <img
          src="@/assets/media/icon/chart.svg"
          alt="chart"
          @click="addCompareId(product.id)"
        />
      </div>
      <div class="compare">
        <img
          src="@/assets/media/icon/cart.svg"
          alt="cart"
          @click="addToCart(product.id)"
        />
      </div>
      <div class="share" @click="showSocialIcon">
        <img src="@/assets/media/icon/share.svg" alt="share" v-if="shareIcon" />
        <div class="social_icons" v-if="socialMediaIcon" @mouseleave="hideSocialIcon">
          <ShareNetwork
            network="sms"
            class="btn"
            :url="$websiteUrl + `product/${product.id}`"
            :title="product.title"
          >
            <i class="fa-solid fa-comment-sms"></i>
          </ShareNetwork>

          <ShareNetwork
            network="messenger"
            class="btn"
            :url="$websiteUrl + `product/${product.id}`"
            :title="product.title"
          >
            <i class="fa-brands fa-facebook-messenger"></i>
          </ShareNetwork>

          <ShareNetwork
            network="facebook"
            class="btn"
            :url="$websiteUrl + `product/${product.id}`"
            :title="product.title"
          >
            <i class="fa-brands fa-facebook"></i>
          </ShareNetwork>

          <ShareNetwork
            network="WhatsApp"
            class="btn"
            :url="$websiteUrl + `product/${product.id}`"
            :title="product.title"
          >
            <i class="fa-brands fa-whatsapp"></i>
          </ShareNetwork>
        </div>
      </div>
    </div>
    <router-link :to="`/product/${product.id}`" class="image_product">
      <img :src="product.image" alt="product" />
    </router-link>
    <router-link :to="`/product/${product.id}`" class="product-card-data">
      <p class="title">{{ product.title }}</p>
      <p class="text" v-html="product.text.length > 100 ? product.text.substring(0, 100) + ' ...' : product.text.substring(0, 100)"></p>
      <p class="title" :class="`${product.amount == 0 ? 'out_stock' : 'in_stock'}`">{{ product.amount == 0 ? $t("out_stock") : $t("in_stock") }}</p>
      <div class="rate">
        <rate :length="5" v-model="product.rate" :disabled="true" />
      </div>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["product"],
  data() {
    return {
      compareList: [],
      auth: this.$store.getters['isAuthenticated'],
      shareIcon: true,
      socialMediaIcon: false
    };
  },
  methods: {
    AddWishList(id) {
      let product_id = {product_id : id};
      this.auth = this.$store.getters['isAuthenticated'];
      if(this.auth) {
        axios.post("/favourite_products", product_id).then(({ data }) => {
          // location.reload();
          Swal.fire({
            icon: "error",
            position: "center",
            icon: "success",
            title: this.$t("The product was added from the wishlist"),
            showConfirmButton: true,
            timer: 2500,
          });
          // this.$router.push("/wishlist")
          this.product.is_favourite = !this.product.is_favourite;
        });
      }
      else if (!this.auth) {
        Swal.fire({
          icon: "error",
          position: "center",
          icon: "warning",
          title: this.$t("please_login"),
          showConfirmButton: true,
          timer: 2500,
        });
      }
    },
    RemoveWishList(id) {
      this.auth = this.$store.getters['isAuthenticated'];
      if(this.auth) {
        axios.delete(`/favourite_products/${id}`).then(({ data }) => {
          Swal.fire({
            icon: "error",
            position: "center",
            icon: "warning",
            title: this.$t("The product has been removed from the wishlist"),
            showConfirmButton: true,
            timer: 2500,
          });
          // this.$router.push("/wishlist")
          this.product.is_favourite = !this.product.is_favourite;
          // console.log(this.questionnaires);
        });
      }
      else if (!this.auth) {
        Swal.fire({
          icon: "error",
          position: "center",
          icon: "warning",
          title: this.$t("please_login"),
          showConfirmButton: true,
          timer: 2500,
        });
      }
    },
    addCompareId(id) {
      // Push the new item to the array
      this.compareList.push(id);

      // Save the updated array to LocalStorage
      localStorage.setItem("CompareList", JSON.stringify(this.compareList));
      this.$router.push({ path: "/compare" });
    },
    // addToCart(product_id) {
    //   const formData = new FormData();
    //   formData.append("product_id", product_id);
    //   formData.append("quantity", 1);
    //   if(this.auth) {
    //     window.scrollTo({
    //       bottom: 0,
    //       behavior: 'smooth' // Add smooth scrolling behavior
    //     });
    //     axios
    //       .post("/cart", formData)
    //       .then((response) => {
    //         Swal.fire({
    //           title: "",
    //           position: "center",
    //           text: response.data.message,
    //           icon: "success",
    //           showConfirmButton: true,
    //           timer: 5000,
    //         });
    //       })
    //       .catch((error) => {
    //         Swal.fire({
    //           title: "",
    //           position: "center",
    //           text: error.response.data.message,
    //           icon: "error",
    //           showConfirmButton: true,
    //           timer: 5000,
    //         });
    //       });
    //   }
    //   else if (!this.auth) {
    //     Swal.fire({
    //       icon: "error",
    //       position: "center",
    //       icon: "warning",
    //       title: this.$t("please_login"),
    //       showConfirmButton: true,
    //       timer: 2500,
    //     });
    //   }
    // },
    addToCart(productId) {
      if (this.auth) {
        window.scrollTo({
          bottom: 0,
          behavior: 'smooth'
        });
        this.$store.dispatch('addToCart', productId);
      } else {
        Swal.fire({
          icon: "error",
          position: "center",
          icon: "warning",
          title: this.$t("please_login"),
          showConfirmButton: true,
          timer: 2500,
        });
      }
    },
    showSocialIcon() {
      this.shareIcon = false
      this.socialMediaIcon = true
    },
    hideSocialIcon() {
      this.shareIcon = true
      this.socialMediaIcon = false
    }
  },
};
</script>
