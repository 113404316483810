<template>
    <h5 v-if="features.length">{{$t("key_features")}} :</h5>
    <ul class="feature" v-if="features.length">
        <li v-for="(feature, item) in features" :key="item">{{feature.title}}</li>
    </ul>
    <h5 v-if="specifications.length">{{$t("specifications_detail")}} :</h5>
    <ul class="specifications_detail" v-for="(specifications, index) in specifications" :key="index">
        <li>
            <span class="title">{{specifications.title}} :</span>
            <span class="text" v-html="specifications.text"></span>
        </li>
    </ul>
</template>
<script>
export default {
  props: ["specifications", "features"],
};
</script>