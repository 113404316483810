<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="data_profile">
      <h2>{{ $t("address") }}</h2>
      <currentAddress :currentAddresses="currentAddresses" :addressesData="addressesData" />
      <hr />
      <form v-on:submit.prevent="SubmitForm" v-if="currentAddresses.length < 5">
        <h4>{{$t("Add New Address")}}</h4>
        <div class="form-group" v-for="(address, index) in addresses" :key="index">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-10 col-12">
              <label for="">{{ $t("country") }}</label>
              <Multiselect
                v-model="addresses[index].country_id"
                :options="countries"
                label="title"
                track-by="id"
                :clear-on-select="true"
                placeholder=""
                select-label=""
                :hide-selected="true"
                @update:model-value="getcities($event)"
              >
              </Multiselect>
            </div>
            <div class="col-lg-3 col-md-10 col-12">
              <label for="">{{ $t("city") }}</label>
              <input
                type="text"
                name=""
                id=""
                v-model="addresses[index].city_id"
                class="form-control"
                maxlength="200"
              />
            </div>
            <div class="col-lg-3 col-md-10 col-12">
              <label for="">{{ $t("address") }} - {{ index + 1 }}</label>
              <input
                type="text"
                name=""
                id=""
                v-model="addresses[index].title"
                class="form-control"
                maxlength="200"
              />
            </div>
            <!-- <div class="col-lg-2 col-md-2 col-2 mt-4">
              <div
                class="minus"
                v-on:click="removeAddress(index)"
                v-if="addresses.length - 1 >= index && addresses.length - 1 != index"
              >
                <i class="fa-solid fa-circle-minus text-danger fa-xl"></i>
              </div>
            </div> -->
          </div>
        </div>
        <button type="submit" class="btn submit_btn" :disabled="disableButton">
          {{ $t("save_changes") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { countriesData } from "@/api/profile/address/countries.js";
// import { addressesData } from "@/api/profile/address/addresses.js";
import currentAddress from './currentAddress.vue';
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      addresses: [
        {
          country_id: "",
          city_id: "",
          title: ""
        }
      ],
      countries: [],
      cities: [],
      currentAddresses: [],
      disableButton: false
    };
  },
  components: {
    currentAddress,
    Multiselect
  },
  created() {
    countriesData().then((response) => {
      this.countries = response.data.data;
      // console.log(this.wishlists);
      return response;
    });
    this.addressesData()
  },
  methods: {
    // get city by country
    getcities(e) {
      let country_id = e.id;
      axios.get(`/cities?country_id=${country_id}`).then(({ data }) => {
        for (let index = 0; index < this.countries.length; index++) {
          this.cities = data.data;
        }
      });
    },
    // get city by country

    addressesData() {
      axios.get(`/addresses`).then(({ data }) => {
        this.currentAddresses = data.data;
      });
    },

    SubmitForm() {
      this.disableButton = true; //the disableButton begin
      const formData = new FormData();
      for (let index = 0; index < this.addresses.length; index++) {
        formData.append(`address[${index}][country_id]`, this.addresses[index].country_id.id);
        formData.append(`address[${index}][city_title]`, this.addresses[index].city_id.id);
        formData.append(`address[${index}][title]`, this.addresses[index].title);
      }
      axios
        .post("/add_address", formData)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            for (let index = 0; index < this.addresses.length; index++) {
              this.addresses[index].country_id = "";
              this.addresses[index].city_id = "";
              this.addresses[index].title = "";
            }
            this.disableButton = false;
            this.addressesData()
          } else {
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            this.disableButton = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: true,
            timer: 6000,
          });
          // this.$router.push({ path: "/teachers" });
          this.disableButton = false;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 4rem;
}
select {
  -webkit-appearance: listbox !important;
}
.data_profile {
  width: 70%;
  margin-bottom: 2rem;
}
.data_profile .submit_btn, .data_profile .plus {
  width: 80%;
}
hr {
  margin: 3rem 0;
}
h4 {
  font-family: "regular";
  margin-top: 3rem;
  margin-bottom: 1rem;
}
</style>
