<template>
  <div class="offer" id="offers" v-if="status == true && offers.length">
    <div class="container">
      <h3 class="title_head">{{$t ("offers")}}</h3>
      <swiper
        :pagination="{
          clickable: true,
        }"
        :loop="true"
        :modules="modules"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        class="offersSwiper"
      >
        <swiper-slide
          class="card_offer"
          v-for="(offer, index) in offers"
          :key="index"
        >
          <img
            :src="offer.image"
            alt="offer"
          />
          <div class="text_offer">
            <h1 class="title">{{ offer.title }}</h1>
            <p class="text" v-html="offer.text"></p>
            <button type="button" class="btn" @click="addToCart(offer.id)">{{$t ("add_to_cart")}}</button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

import { offerData } from "@/api/index/offer.js";

export default {
  data() {
    return {
      status: "",
      offers: [],
      auth: this.$store.getters['isAuthenticated']
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
  methods: {
    addToCart(offerID) {
      let offer_id = offerID;
      const formData = new FormData();
      formData.append("offer_id", offer_id);
      formData.append("quantity", 1);
      if(this.auth) {
        axios
          .post("/cart", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/cart");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
      else if (!this.auth) {
        Swal.fire({
          type: "error",
          position: "center",
          icon: "warning",
          title: this.$t("please_login"),
          showConfirmButton: true,
          timer: 2500,
        });
      }
    },
  },
  mounted() {
    offerData().then((response) => {
      this.status = response.data.status;
      this.offers = response.data.data;
      return response;
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>