<template>
  <div class="best_seller" id="best_seller" v-if="sellers.length">
    <div class="container">
      <h3 class="title_head">{{ $t("best_seller") }}</h3>
      <div class="seller">
        <swiper
          :slidesPerView="2"
          :spaceBetween="30"
          :slidesPerGroup="1"
          :loop="true"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
          }"
          :loopFillGroupWithBlank="true"
          :navigation="true"
          :modules="modules"
          :breakpoints="{
            300: {
              slidesPerView: 1,
              // slidesPerGroup: 1,
              loopFillGroupWithBlank: false,
            },
            768: {
              slidesPerView: 1,
              loopFillGroupWithBlank: false,
            },
            1024: {
              slidesPerView: 2,
              loopFillGroupWithBlank: true,
            },
          }"
          class="mySwiper"
        >
          <swiper-slide v-for="(seller, index) in sellers" :key="index">
            <seller :seller="seller" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>


<script>
import { sellerData } from "@/api/index/seller.js";
import seller from "../../include/index/card_seller.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  data() {
    return {
      sellers: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    seller,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  mounted() {
    sellerData().then((response) => {
      this.sellers = response.data.data;
      // console.log(this.sellers);
      return response;
    });
  },
};
</script>
