<template>
  <div class="head_product">
    <h6 class="image">{{$t("Item")}}</h6>
    <h6 class="price">{{$t("Price")}}</h6>
    <h6 class="quantity">{{$t("QTY")}}</h6>
    <h6 class="sub_total">{{$t("Subtotal")}}</h6>
  </div>
  <div class="product_card_cart" v-for="(cart, index) in carts" :key="index">
    <productCard :cart="cart" v-if="cart.product" :callCartMethod="callCartMethod" />
    <offerCard :cart="cart" v-if="cart.offer" :callCartMethod="callCartMethod" />
    <button class="btn remove" @click="removeProductCart(cart.id)"><i class="fa-regular fa-trash-can"></i> {{$t("remove")}}</button>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import productCard from './productCard.vue';
import offerCard from './offerCard.vue';

export default {
  name: "product-card",
  props: {
    carts: Array,
    callCartMethod: Function
  },
  components: {
    productCard,
    offerCard
  },
  methods: {
    async removeProductCart(item_id) {
      try {
        const response = await axios.delete(`/delete_item/${item_id}`);
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "success",
        });
        this.callCartMethod();
      } catch (error) {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      } finally {
        
      }
    },
  },
};
</script>

<style></style>
