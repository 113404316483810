<template>
  <breadcrumb :title="title" :subTitle="product.title" :link="link" />
  <div class="single_product">
    <div class="container">
      <div class="row">
        <div class="header_single_product">
          <swiper
            @swiper="setThumbsSwiper"
            :spaceBetween="10"
            :slidesPerView="4"
            :freeMode="true"
            :direction="'vertical'"
            :watchSlidesProgress="true"
            class="mySwiper SubImages mt-3"
          >
            <swiper-slide v-for="image in product.images" :key="image.id">
              <img :src="image.image" :alt="image.id" />
            </swiper-slide>
          </swiper>
          <swiper
            :style="{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }"
            :spaceBetween="10"
            :navigation="true"
            :thumbs="{ swiper: thumbsSwiper }"
            class="mySwiper2 MainImage"
          >
            <swiper-slide v-for="(image, index) in product.images" :key="image.id">
              <img :src="image.image" :alt="image.id" data-bs-toggle="modal" :data-bs-target="`#imageModal${index}`" />
            </swiper-slide>
          </swiper>
          <showImages :images="product.images" />
          <div class="description_product">
            <div class="head_details_product">
              <div class="details">
                <h3 class="name">{{ product.title }}</h3>
                <div class="brand">
                  <span class="title">{{$t("brand")}}: </span>
                  <span class="text">{{ brand.title }}</span>
                </div>
                <div class="availability">
                  <span class="title">{{$t("availability")}}: </span>
                  <span
                    :class="`text ${
                      product.amount >= 1 ? 'inStock' : 'outOfStock'
                    }`"
                    >{{
                      product.amount >= 1 ? $t("in_stock") : $t("out_stock")
                    }}</span
                  >
                </div>
                <!-- <div class="brand">
                  <span class="title">{{$t("price")}}: </span>
                  <span class="price">{{ product.price }} {{$t("egp")}}</span>
                </div> -->
                <div class="rating">
                  <!-- {{overallReview.avg_rate}} -->
                  <!-- <rate :length="5" :value="product.rate" /> -->
                  <i
                    class="fa-solid fa-star"
                    v-for="(star, item) in product.rate"
                    :key="item"
                  ></i>
                  <!-- {{5-product.rate}} -->
                  <i
                    class="fa-regular fa-star"
                    v-for="(star, item) in avg_rate"
                    :key="item"
                  ></i>
                  <span>{{ overallReview.num_of_reviews }} {{$t("ratings")}}</span>
                </div>
              </div>
                
              <div class="actions">
                <div class="form-check heart">
                  <img src="@/assets/media/icon/heart.svg" v-if="product.is_favourite == 0" :class="`${product.is_favourite == 0 ? '' : 'favourite'}`" alt="heart" @click="AddWishList(product.id)" />
                  <img src="@/assets/media/icon/favourite.svg" v-else-if="product.is_favourite == 1" :class="`${product.is_favourite == 0 ? '' : 'favourite'}`" alt="heart" @click="RemoveWishList(product.id)" />
                </div>
                <div class="compare">
                  <img src="@/assets/media/icon/chart.svg" alt="chart" @click="addCompareId(product.id)" />
                </div>
                <div class="share" @click="showSocialIcon">
                  <img src="@/assets/media/icon/share.svg" alt="share" v-if="shareIcon" />
                  <div class="social_icons" v-if="socialMediaIcon" @mouseleave="hideSocialIcon">
                    <ShareNetwork
                      network="sms"
                      class="btn"
                      :url="$websiteUrl + `product/${product.id}`"
                      :title="product.title"
                    >
                      <i class="fa-solid fa-comment-sms"></i>
                    </ShareNetwork>

                    <ShareNetwork
                      network="messenger"
                      class="btn"
                      :url="$websiteUrl + `product/${product.id}`"
                      :title="product.title"
                    >
                      <i class="fa-brands fa-facebook-messenger"></i>
                    </ShareNetwork>

                    <ShareNetwork
                      network="facebook"
                      class="btn"
                      :url="$websiteUrl + `product/${product.id}`"
                      :title="product.title"
                    >
                      <i class="fa-brands fa-facebook"></i>
                    </ShareNetwork>

                    <ShareNetwork
                      network="WhatsApp"
                      class="btn"
                      :url="$websiteUrl + `product/${product.id}`"
                      :title="product.title"
                    >
                      <i class="fa-brands fa-whatsapp"></i>
                    </ShareNetwork>
                  </div>
                </div>
              </div>
            </div>
            <p class="product_out_of_stock">
              {{ product.amount >= 1 ? "" : $t("This product is currently out of stock, click here to get notified once it is back in stock")}}
            </p>
            <button v-if="product.amount < 1" class="btn get_product_btn" data-bs-toggle="modal" data-bs-target="#ModalNotifyProduct">
              {{ $t('notify_me') }}
            </button>
            <button v-if="product.amount >= 1" class="btn get_product_btn" @click="addToCart()">
              {{ $t('get_the_product_now') }}
            </button>
            <ModalNotifyProduct :product="product" />
          </div>
        </div>

        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-details-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-details"
              type="button"
              role="tab"
              aria-controls="pills-details"
              aria-selected="true"
            >
              {{$t("product_details")}}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-specifications-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-specifications"
              type="button"
              role="tab"
              aria-controls="pills-specifications"
              aria-selected="false"
            >
              {{$t("specifications")}}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-reviews-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-reviews"
              type="button"
              role="tab"
              aria-controls="pills-reviews"
              aria-selected="false"
            >
              {{$t("customer_reviews_ratings")}}
            </button>
          </li>
        </ul>
        <div class="tab-content p-0" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-details"
            role="tabpanel"
            aria-labelledby="pills-details-tab"
            tabindex="0"
          >
            <detailsProduct :product_details="product_details" />
          </div>
          <div
            class="tab-pane fade specifications"
            id="pills-specifications"
            role="tabpanel"
            aria-labelledby="pills-specifications-tab"
            tabindex="0"
          >
            <specifications v-if="specifications.length || features.length" :specifications="specifications" :features="features" />
          </div>
          <div
            class="tab-pane fade"
            id="pills-reviews"
            role="tabpanel"
            aria-labelledby="pills-reviews-tab"
            tabindex="0"
          >
            <reviews :reviews="reviews" :productData="productData" :overallReviewAvgRate="overallReviewAvgRate" :overallReviewRate="overallReviewRate" :product="product" :overallReview="overallReview" />
          </div>
        </div>
        <relatedProducts />
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "@/components/layouts/breadcrumb.vue";
// import { productData } from "@/api/product/product_details.js";
import detailsProduct from "@/components/include/product/details_product.vue";
import specifications from "@/components/include/product/specifications.vue";
// import product from "@/components/include/product/reviews.vue";
import reviews from "@/components/include/product/reviews.vue";
import rate from "@/components/include/product/reviews.vue";
// import ModalBuyProduct from "@/components/include/product/buy_product.vue";
import ModalNotifyProduct from "@/components/include/product/notify_product.vue";
import relatedProducts from '@/components/include/product/related_products.vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import Swiper core and required modules
import SwiperCore, { Autoplay, FreeMode, Navigation, Thumbs } from "swiper";
import showImages from '@/components/include/product/showImages.vue';

// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs]);

import { ref } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "library",
  components: {
    Swiper,
    SwiperSlide,
    relatedProducts,
    breadcrumb: breadcrumb,
    detailsProduct: detailsProduct,
    specifications: specifications,
    reviews: reviews,
    rate: rate,
    showImages,
    // ModalBuyProduct: ModalBuyProduct,
    ModalNotifyProduct: ModalNotifyProduct,
  },
  data() {
    return {
      title: this.$t("products"),
      link: "/products",
      thumbsSwiper: null,
      product: {},
      product_details: "",
      features: [],
      specifications: [],
      reviews: [],
      brand: {},
      image: "",
      overallReview: {
        avg_rate: ""
      },
      avg_rate: "",
      overallReviewAvgRate: null,
      overallReviewRate: null,
      compareList: [],
      auth: this.$store.getters['isAuthenticated'],
      shareIcon: true,
      socialMediaIcon: false
    };
  },
  // mounted() {
  //   // Retrieve the existing list from LocalStorage and convert to an array
  //   const listFromLocalStorage = localStorage.getItem('CompareList')
  //   if (listFromLocalStorage) {
  //     this.compareList = JSON.parse(listFromLocalStorage)
  //   }
  // },
  methods: {
    addCompareId(id) {
      // Push the new item to the array
      this.compareList.push(id)

      // Save the updated array to LocalStorage
      localStorage.setItem('CompareList', JSON.stringify(this.compareList));
      this.$router.push({ path: "/compare" });
    },

    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
    AddWishList(id) {
      let product_id = {product_id : id};
      if(this.auth) {
        axios.post("/favourite_products", product_id).then(({ data }) => {
          Swal.fire({
            icon: "error",
            position: "center",
            icon: "success",
            title: this.$t("The product was added from the wishlist"),
            showConfirmButton: true,
            timer: 2500,
          });
          this.product.is_favourite = !this.product.is_favourite;
          // console.log(this.questionnaires);
        });
      }
      else if (!this.auth) {
        Swal.fire({
          type: "error",
          position: "center",
          icon: "warning",
          title: this.$t("please_login"),
          showConfirmButton: true,
          timer: 2500,
        });
      }
    },
    RemoveWishList(id) {
      axios.delete(`/favourite_products/${id}`).then(({ data }) => {
        Swal.fire({
          icon: "error",
          position: "center",
          icon: "warning",
          title: this.$t("The product has been removed from the wishlist"),
          showConfirmButton: true,
          timer: 2500,
        });
        this.product.is_favourite = !this.product.is_favourite;
        // console.log(this.questionnaires);
      });
    },

    productData() {
      let product_id = this.$route.params.id;
      axios
        .get(`/product_details/${product_id}`)
        .then((response) => {
          this.ModalNotifyProduct = response.data.data.id;
          this.overallReview = response.data;
          this.product = response.data.data;
          this.avg_rate = 5-response.data.data.rate;
          this.image = response.data.data.images[0].image;
          this.product_details = response.data.data.text;
          this.features = response.data.data.features;
          this.brand = response.data.data.brand;
          this.specifications = response.data.data.specifications;
          this.reviews = response.data.data.reviews;
          this.overallReviewAvgRate = Math.round(response.data.avg_rate);
          this.overallReviewRate = 5-Math.round(response.data.avg_rate);
          // console.log(this.product)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    addToCart() {
      let product_id = this.$route.params.id;
      const formData = new FormData();
      formData.append("product_id", product_id);
      formData.append("quantity", 1);
      if(this.auth) {
        axios
          .post("/cart", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            // this.$router.push("/cart");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
      else if (!this.auth) {
        Swal.fire({
          type: "error",
          position: "center",
          icon: "warning",
          title: this.$t("please_login"),
          showConfirmButton: true,
          timer: 2500,
        });
      }
    },
    showSocialIcon() {
      this.shareIcon = false
      this.socialMediaIcon = true
    },
    hideSocialIcon() {
      this.shareIcon = true
      this.socialMediaIcon = false
    }
  },
  setup() {
    const visibleRef = ref(false);
    const indexRef = ref(0); // default 0
    const imgsRef = ref([]);
    // Img Url , string or Array of string
    // ImgObj { src: '', title: '', alt: '' }
    // 'src' is required
    // allow mixing
    const onShow = () => {
      visibleRef.value = true;
    };
    const showMultiple = () => {
      imgsRef.value = [];
      indexRef.value = 0; // index of imgList
      onShow();
    };
    const onHide = () => (visibleRef.value = false);
    return {
      visibleRef,
      indexRef,
      imgsRef,
      showMultiple,
      onHide,
      modules: [Autoplay, Navigation],
    };
  },
  created() {
    this.productData()
  },
  watch: {
    $route: {
      handler: "productData",
      immediate: true,
    },
  },
};
</script>