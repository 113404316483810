import axios from "axios";
export default {
  async Verify({ commit }, Form) {
    let response = await axios.post("/verify", Form);
    localStorage.setItem("token", response.data.data.token);
    const UserResource = {
      name : response.data.data.name,
      phone : response.data.data.phone,
      phone_code : response.data.data.phone_code,
      email: response.data.data.email,
    };
    localStorage.setItem("user",  JSON.stringify(UserResource));
    commit("setUser", { user: response });
    return response;
  },
  async Login({ commit }, Form) {
    try {
      let response = await axios.post("/login", Form);
      localStorage.setItem("token", response.data.data.token);
      const UserResource = {
        name : response.data.data.name,
        phone : response.data.data.phone,
        phone_code : response.data.data.phone_code,
        email: response.data.data.email,
      };
      localStorage.setItem("user",  JSON.stringify(UserResource));
      commit("setUser", { user: response });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  logout({ commit }) {
    commit("logout");
  },
};
