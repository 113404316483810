<template>
  <div class="categories">
    <div class="container" v-if="status == true && categories.length">
      <swiper
        :slidesPerView="4"
        :spaceBetween="30"
        :slidesPerGroup="1"
        :loopFillGroupWithBlank="true"
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          300: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: 4,
            loopFillGroupWithBlank: true,
          },
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(category, index) in categories" :key="index">
          <div class="card_category">
            <img :src="category.image" :alt="category.title" />
            <p class="title" :data-title="category.title">
              {{
                category.title.length > 20
                  ? category.title.substring(0, 20) + " ..."
                  : category.title.substring(0, 20)
              }}
            </p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { categoriesData } from "@/api/index/category.js";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay } from "swiper";

import "swiper/css/pagination";

export default {
  data() {
    return {
      status: "",
      categories: [],
    };
  },
  mounted() {
    categoriesData().then((response) => {
      this.status = response.data.status;
      this.categories = response.data.data;
      return response;
    });
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
