<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="data_profile w-100">
      <h3 class="title_section mb-5">{{ $t("Order History") }}</h3>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="order_tabs">
          <router-link
            v-for="(order, index) in orders"
            :key="index"
            :to="`/my_order/${order.id}`"
          >
            <div class="details">
              <p class="id">#{{ order.id }}</p>
              <p class="price">{{ order.total_price }} {{ $t("egp") }}</p>
              <p class="count">{{ order.items_count }} {{ $t("Item") }}</p>
            </div>
            <div class="status_order">
              <p class="status">
                {{
                  order.status == "pending"
                    ? $t("pending")
                    : order.status == "preparing"
                    ? $t("preparing")
                    : order.status == "out_for_delivery"
                    ? $t("Out For Delivery")
                    : order.status == "delivered"
                    ? $t("Delivered")
                    : order.status == "finished"
                    ? $t("Finished")
                    : "client_cancel"
                    ? $t("client_cancel")
                    : order.status == "provider_cancel"
                    ? $t("provider_cancel")
                    : order.status == "provider_reject"
                    ? $t("provider_reject")
                    : ""
                }}
              </p>
              <p class="date">{{ order.created_at }}</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-12"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      orders: [],
    };
  },
  methods: {
    fetchOrders() {
      axios
        .get("/orders")
        .then((response) => {
          this.orders = response.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetchOrders();
  },
};
</script>

<style>
.breadcrumb {
  margin-bottom: 4rem;
}
</style>
