<template>
  <div class="row">
    <div class="col-lg-4 col-12">
      <div class="overall_rating">
      <h4>{{$t("overall_rating")}}</h4>
        <h2>{{ Math.round(overallReview.avg_rate) }}</h2>
        <div class="rate">
          <!-- <rate :length="5" :value="rate" /> -->
          <i
            class="fa-solid fa-star"
            v-for="(star, item) in overallReviewAvgRate"
            :key="item"
          ></i>
          <i
            class="fa-regular fa-star"
            v-for="(star, item) in overallReviewRate"
            :key="item"
          ></i>
        </div>
        <p>{{$t("based_on")}} {{ overallReview.num_of_reviews }} {{$t("ratings")}}</p>
      </div>
      <div class="progress_rate">
        <div class="bar_rate">
          <span>5 {{$t("star")}}</span>
          <div class="progress">
            <div class="bar" :style="`width:${overallReview.five_stars}%`"></div>
          </div>
        </div>
        <div class="bar_rate">
          <span>4 {{$t("star")}}</span>
          <div class="progress">
            <div class="bar" :style="`width:${overallReview.four_stars}%`"></div>
          </div>
        </div>
        <div class="bar_rate">
          <span>3 {{$t("star")}}</span>
          <div class="progress">
            <div class="bar" :style="`width:${overallReview.three_stars}%`"></div>
          </div>
        </div>
        <div class="bar_rate">
          <span>2 {{$t("star")}}</span>
          <div class="progress">
            <div class="bar" :style="`width:${overallReview.two_stars}%`"></div>
          </div>
        </div>
        <div class="bar_rate">
          <span>1 {{$t("star")}}</span>
          <div class="progress">
            <div class="bar" :style="`width:${overallReview.one_star}%`"></div>
          </div>
        </div>
      </div>
      <div class="write_review">
        <h4>{{$t("review_product")}}</h4>
        <p>{{$t("share_customer")}}</p>
        <button class="btn" data-bs-toggle="modal" data-bs-target="#writereviewModal">{{$t("write_review")}}</button>
        <ModalWriteReview :product="product" :productData="productData" />
      </div>
    </div>
    <div class="col-lg-8 col-12">
      <div class="reviews_cards" v-if="showFirstThree">
        <!-- For loop this person review -->
        <div class="person_review" v-for="(user, index) in reviews.slice(0, 3)" :key="index">
          <p class="name">
            <span>{{user.name}}</span>
            <button class="btn" @click="toggleDisplay" v-if="index == 0 && reviews.length > 3">{{$t("See all reviews")}}</button>
          </p>
          <div class="rate">
            <rate :length="5" :value="user.rate" :disabled="true" />
          </div>
          <span class="date">{{user.date}}</span>
          <p class="title">{{user.title}}</p>
          <p class="text">{{user.text}}</p>
        </div>
        <!-- For loop this person review -->
      </div>

      <div class="reviews_cards" v-else>
        <!-- For loop this person review -->
        <div class="person_review" v-for="(user, index) in reviews" :key="index">
          <p class="name">
            <span>{{user.name}}</span>
            <button class="btn" @click="toggleDisplay" v-if="index == 0 && reviews.length > 3">{{$t("Show less reviews")}}</button>
          </p>
          <div class="rate">
            <rate :length="5" :value="user.rate" :disabled="true" />
          </div>
          <span class="date">{{user.date}}</span>
          <p class="title">{{user.title}}</p>
          <p class="text">{{user.text}}</p>
        </div>
        <!-- For loop this person review -->
      </div>
    </div>
  </div>
</template>

<script>
import ModalWriteReview from "../../include/product/write_review.vue";

export default {
  props: ["product", "reviews", "overallReviewAvgRate", "overallReviewRate", "overallReview", "productData"],
  data() {
    return {
      showFirstThree: true
    };
  },
  components: {
    ModalWriteReview: ModalWriteReview,
  },
  methods: {
    toggleDisplay() {
      this.showFirstThree = !this.showFirstThree;
    }
  }
};
</script>