<template>
<!-- Modal -->
<div class="modal fade" id="PrivacyModal" tabindex="-1" aria-labelledby="PrivacyModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="PrivacyModalLabel">{{$t("privacy")}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="text" v-html="privacy.text"></p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { privacyData } from "@/api/static/privacy.js";

export default {
  data() {
    return {
      privacy: {},
    };
  },
  mounted() {
    privacyData().then((response) => {
      this.privacy = response.data.data;
      // console.log(response.data);
      return response;
    });
  },
};
</script>

<style>

</style>