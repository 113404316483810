<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="review_data">
      <h2>{{$t("My Product Reviews")}}</h2>
      <ReviewCard :reviews="reviews" />
    </div>
  </div>
</template>

<script>
import { reviewsData } from "@/api/profile/review_product.js";
import ReviewCard from './ReviewCard.vue';

export default {
  data() {
    return {
      reviews: [],
    };
  },
  components: {
    ReviewCard
  },
  created() {
    reviewsData().then((response) => {
      this.reviews = response.data.data;
      // console.log(this.reviews);
      return response;
    });
  },
};
</script>

<style>
.breadcrumb {
  margin-bottom: 4rem;
}
</style>