<template>
  <div class="feature_products" id="Features" v-if="products.length">
    <div class="container">
      <h3 class="title_head">{{$t ("feature_products")}}</h3>
      <swiper
        :slidesPerView="4"
        :spaceBetween="30"
        :slidesPerGroup="1"
        :autoplay="{
          delay: 10000,
          disableOnInteraction: false,
        }"
        :loopFillGroupWithBlank="true"
        :navigation="true"
        :modules="modules"
        :breakpoints="{
          300: {
            slidesPerView: 1,
            // slidesPerGroup: 1,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 2,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: 4,
            loopFillGroupWithBlank: true,
          },
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(product, index) in products" :key="index">
          <product :product="product" />
        </swiper-slide>
      </swiper>
      <router-link to="/products" class="btn view_all"
        >{{$t ("view_all_product")}}</router-link
      >
    </div>
  </div>
</template>

<script>
import product from "../../include/product/product.vue";
import { productsData } from "@/api/index/feature_products.js";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  data() {
    return {
      products: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    product: product,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  mounted() {
    productsData().then((response) => {
      this.products = response.data.data;
      // console.log(this.products);
      return response;
    });
  },
};
</script>