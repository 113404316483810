<template>
  <div class="cart_summary">
    <h5 class="title">{{ $t("cart_summary") }}</h5>
    <productCartSummary :products="products" />
    <ul class="pricing">
      <li>
        <p class="title_price">{{ $t("Subtotal") }}</p>
        <!-- {{ total_price.sub_total }} -->
        <p class="text">0 {{ $t("egp") }}</p>
      </li>
      <li>
        <p class="title_price">{{ $t("Shipping amount") }}</p>
        <!-- {{ total_price.shipping }} -->
        <p class="text">0 {{ $t("egp") }}</p>
      </li>
    </ul>
    <div class="total">
      <p>{{ $t("total") }}</p>
      <!-- {{ total_price.total }} -->
      <p>0 {{ $t("egp") }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import productCartSummary from "./productCartSummary.vue";

export default {
  data() {
    return {
      auth:false,
      products: [],
      total_price: {},
    };
  },
  components: {
    productCartSummary,
  },
  methods: {
    fetchCart() {
      axios
        .get("/cart")
        .then((response) => {
          this.products = response.data.data.data;
          this.total_price = response.data.total_price;
          // console.log(this.products)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.auth = this.$store.getters['isAuthenticated'];
    if(this.auth) {
      this.fetchCart();
    }
  },
};
</script>

<style></style>
