import { createStore } from 'vuex';

import authModule from './modules/auth/index.js';
import cartModule from './modules/cartModule.js'; // Import your cart module

const store = createStore({
  modules: {
    auth: authModule,
    cart: cartModule // Register your cart module
  }
});

export default store;