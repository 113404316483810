import axios from 'axios';

export default function setupAxios() {
  // axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
  axios.defaults.baseURL = 'https://admin.elsaygh.com/api';
  // axios.defaults.baseURL = 'https://elsaygh-store-admin.techysquare.com/api';

  // Set authorization header if token exists in localStorage
  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}
