<template>
  <div
    class="modal ModalNotify fade"
    id="ModalNotifyProduct"
    tabindex="-1"
    aria-labelledby="ModalNotifyProductLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <h2>{{$t("notify_me")}}</h2>
          <form v-on:submit.prevent="SubmitForm">
            <div class="form-group">
              <input type="text" :value="product.id" hidden v-on:input="Form.id = $event.target.value" />
              <input
                type="email"
                name=""
                id=""
                class="form-control"
                v-model="Form.email"
                :placeholder="$t('your_email')"
                :disabled="auth"
                required
              />
              <button type="submit" class="btn" :disabled="disableButton">{{$t("notify_me")}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["product"],
  data() {
    return {
      Form: {
        product_id: this.$route.params.id,
        email: this.$store.getters['isAuthenticated'] ? JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).email : "",
      },
      disableButton: false,
      auth: this.$store.getters['isAuthenticated']
    };
  },
  methods: {
    SubmitForm() {
      this.disableButton = true; //the disableButton begin

      axios
        .post("/notify_me", this.Form)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            if (!this.auth) {
              this.Form.email = ""
            }
            this.disableButton = false;
          } else {
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            this.disableButton = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: true,
            timer: 6000,
          });
          // this.$router.push({ path: "/teachers" });
          this.disableButton = false;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  }
};
</script>

<style>
</style>