<template>
    <div class="container">
        <div class="compare_search">
            <h3>{{$t("Compare Products")}}</h3>
            <input type="search" class="form-control" v-model="nameProduct" @keyup="getProductCompare">
        </div>
        <div class="result_product" v-if="nameProduct.length">
            <div class="product_search_card" v-for="(product, index) in productsSearch" :key="index" @click="selectProduct(product.id)">
                <img :src="product.image" :alt="product.title">
                <div class="data">
                    <p class="title">{{product.title.substring(0, 100) + ' ...'}}</p>
                    <span class="text" v-html="product.text.substring(0, 100) + ' ...'"></span>
                    <div class="rate">
                        <i
                            class="fa-solid fa-star"
                            v-for="(star, item) in product.rate"
                            :key="item"
                        ></i>
                        <i
                            class="fa-regular fa-star"
                            v-for="(star, item) in 5 - product.rate"
                            :key="item"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
        <section class="table_compare">
            <table class="table compare" v-if="products.length">
                <tbody>
                    <tr>
                        <th class="head_compare" scope="row">
                            <h4 class="title">{{$t("General Information")}}</h4>
                        </th>
                        <td class="list_compare" v-for="(product, index) in products" :key="index">
                            <span class="delete_btn btn btn-danger" @click="deleteProduct(product.id)"><i class="fa-solid fa-circle-xmark"></i></span>
                            <div class="data">
                                <router-link :to="`/product/${product.id}`">
                                    <img :src="product.image" :alt="product.title">
                                </router-link>
                                <p class="title">{{product.title.substring(0, 100) + ' ...'}}</p>
                                <div class="brand">
                                    <span>{{$t("brand")}}: </span>
                                    <span>{{product.brand.title}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="head_compare" scope="row">
                            <h4 class="title">{{$t("product_details")}}</h4>
                        </th>
                        <td class="list_compare" v-for="(product, index) in products" :key="index">
                            <div class="data">
                                <span class="title" v-html="product.text.substring(0, 250) + ' ...'"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="head_compare" scope="row">
                            <h4 class="title">{{$t("specifications")}}</h4>
                        </th>
                        <td class="list_compare" v-for="(product, index) in products" :key="index">
                            <div class="data">
                                <ul>
                                    <li v-for="(specification, index) in product.specifications" :key="index">{{specification.title}}</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="head_compare border-bottom-0" scope="row">
                            <h4 class="title">{{$t("customer_reviews_ratings")}}</h4>
                        </th>
                        <td class="list_compare border-bottom-0" v-for="(product, index) in products" :key="index">
                            <div class="data">
                                <p class="rate">{{product.rate}}</p>
                                <i
                                    class="fa-solid fa-star"
                                    v-for="(star, item) in product.rate"
                                    :key="item"
                                ></i>
                                <i
                                    class="fa-regular fa-star"
                                    v-for="(star, item) in 5 - product.rate"
                                    :key="item"
                                ></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row" v-else>
                <NotFound />
                <h3 class="not_found mb-4">{{$t("no_products_in_compare")}}</h3>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import NotFound from '@/components/include/Lottifiles/NotFound.vue';
 
export default {
    data() {
        return {
            products: [],
            nameProduct: "",
            productsSearch: []
        }
    },
    components: {
        NotFound
    },
    // mounted() {
    //     // Retrieve the list from LocalStorage when the component is mounted
    //     let currentList = localStorage.getItem('CompareList')
    //     if (currentList) {
    //         this.products = JSON.parse(currentList)
    //     }
    // },
    methods: {
        compareProduct() {
            const CompareList = localStorage.getItem("CompareList");
            let CompareIds = JSON.parse(CompareList);
            // console.log(CompareIds)

            const formData = new FormData();
            if (CompareIds != null) {
                for (let index = 0; index < CompareIds.length; index++) {
                    formData.append("product_ids[]", CompareIds[index]);
                }
                if (CompareIds.length) {
                    axios.post("/compare_products", formData).then(({ data }) => {
                        this.products = data.data;
                        // console.log(this.products);
                    });
                }
            }

        },
        
        // Get Filter
        getProductCompare() {
            if(this.nameProduct.length > 2){
                axios
                    .get(`/search_product?keyword=${this.nameProduct}`)
                    .then((res) => {
                        this.productsSearch = res.data.data;
                    })
                    .catch((error) => {
                        Swal.fire({
                        title: "",
                        text: error.response.data.message,
                        icon: "error",
                        });
                    });
            }
        },
        // Get Filter

        selectProduct(Product_id) {
            // Get the current list from localStorage
            if(localStorage.getItem('CompareList') == null) {
                localStorage.setItem('CompareList', JSON.stringify(this.products));
                
                let currentList = JSON.parse(localStorage.getItem('CompareList'));
                // console.log(currentList.length)
                if(currentList.length > 2) {
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: this.$t("more_than_product_compare"),
                        showConfirmButton: true,
                    });
                }
                else {
                    // Add the new item to the list
                    const isProductInList = JSON.parse(localStorage.getItem('CompareList')).includes(Product_id);
                    if(!isProductInList) {
                        currentList.push(Product_id)
                        currentList.sort((a, b) => b - a);
        
                        // Set the updated list back to localStorage
                        localStorage.setItem('CompareList', JSON.stringify(currentList));
                        location.reload()
                    }
                    else {
                        Swal.fire({
                            position: "center",
                            icon: "warning",
                            title: this.$t("This product already exists"),
                            showConfirmButton: true,
                        });
                    }
                }
            }
            else {
                let currentList = JSON.parse(localStorage.getItem('CompareList'));
                // console.log(currentList.length)
                if(currentList.length > 2) {
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: this.$t("more_than_product_compare"),
                        showConfirmButton: true,
                    });
                }
                else {
                    // Add the new item to the list
                    const isProductInList = JSON.parse(localStorage.getItem('CompareList')).includes(Product_id);
                    if(!isProductInList) {
                        currentList.push(Product_id)
                        currentList.sort((a, b) => b - a);
        
                        // Set the updated list back to localStorage
                        localStorage.setItem('CompareList', JSON.stringify(currentList));
                        location.reload()
                    }
                    else {
                        Swal.fire({
                            position: "center",
                            icon: "warning",
                            title: this.$t("This product already exists"),
                            showConfirmButton: true,
                        });
                    }
                }
            }

        },

        deleteProduct(Product_id) {

            const CompareList = JSON.parse(localStorage.getItem('CompareList'));

             // Find the index of the item with the specified ID
            const index = this.products.findIndex(item => item.id == Product_id)

            // Use splice() to remove the item from the array
            if (index != -1) {
                CompareList.splice(index, 1)
                let updatedList = JSON.stringify(CompareList);
                // console.log(this.products[index].id)
                localStorage.setItem('CompareList', updatedList);
                location.reload()
            }

            // Convert the updated array back to a string

            // Save the updated string to LocalStorage
        }
    },
    created() {
        this.compareProduct();
    },
    watch: {
        $route: {
            handler: "getProductCompare",
            immediate: true,
        },
    },
    beforeRouteLeave(to, from, next) {
        localStorage.removeItem('CompareList');
        next();
    }
}
</script>