<template>
  <div
    class="card_review_product"
    v-for="(review, index) in reviews"
    :key="index"
  >
    <span class="product_name">{{ review.product.title }}</span>
    <rate :length="5" :value="review.rate" :disabled="true" />
    <!-- <i
      class="fa-solid fa-star"
      v-for="(star, item) in review.rate"
      :key="item"
    ></i>
    <i
      class="fa-regular fa-star"
      v-for="(star, item) in 5 - review.rate"
      :key="item"
    ></i> -->
    <span class="date">{{ review.date }}</span>
    <p class="title">{{ review.title }}</p>
    <p class="text">{{ review.text }}</p>
  </div>
</template>

<script>
export default {
  props: ["reviews"]
};
</script>

<style></style>
