import axios from 'axios';
import Swal from 'sweetalert2';

const state = {
  totalQuantity: 0
};

const getters = {
  totalQuantity: state => state.totalQuantity
};

const mutations = {
  setTotalQuantity(state, quantity) {
    state.totalQuantity = quantity;
  }
};

const actions = {
  async fetchCartLength({ commit }) {
    try {
      const response = await axios.get("/cart");
      commit('setTotalQuantity', response.data.data.meta.total);
    } catch (error) {
      commit('setTotalQuantity', 0);
    }
  },
  async addToCart({ dispatch }, productId) {
    const formData = new FormData();
    formData.append("product_id", productId);
    formData.append("quantity", 1);

    try {
      const response = await axios.post("/cart", formData);
      Swal.fire({
        title: "",
        position: "center",
        text: response.data.message,
        icon: "success",
        showConfirmButton: true,
        timer: 5000,
      });
      dispatch('fetchCartLength'); // Refresh cart length
    } catch (error) {
      Swal.fire({
        title: "",
        position: "center",
        text: error.response.data.message,
        icon: "error",
        showConfirmButton: true,
        timer: 5000,
      });
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
