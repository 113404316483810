<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="notification_data">
      <h2>{{$t("notification")}}</h2>
      <Notification :notifications="notifications" />
      <button class="btn clear_btn">{{$t("clear")}}</button>
    </div>
  </div>
</template>

<script>
import Notification from "@/components/include/profile/notication/notication_card.vue"
import { notificationsData } from "@/api/profile/notification.js";

export default {
  data() {
    return {
      notifications: [],
    };
  },
  components: {
    Notification
  },
  mounted() {
    notificationsData().then((response) => {
      this.notifications = response.data.data.notification;
      // console.log(this.reviews);
      return response;
    });
  },
};
</script>

<style>
  .breadcrumb {
    margin-bottom: 4rem;
  }
</style>