<template>
  <section class="forget_password">
    <div class="container">
      <form class="auth" @submit.prevent="SubmitForm">
        <h2>{{ $t("Verification Required") }}</h2>
        <p class="text">
          {{$t("One Time Password (OTP) Sent To")}}
          +{{phone_code}}{{phone}}
          {{$t("Please Enter It Below")}}
        </p>
        <div class="form-group">
          <input
            type="number"
            name=""
            id=""
            class="form-control"
            :placeholder="$t('Enter OTP')"
            v-model="code"
          />
        </div>
        <button type="submit" class="btn submit_btn">
          {{ $t("Continue") }}
        </button>
        <p class="btn resent_otp" @click="resendOtp">{{$t("resend otp")}}</p>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      phone: JSON.parse(localStorage.getItem("user")).phone,
      phone_code: JSON.parse(localStorage.getItem("user")).phone_code,
      code: "",
      userData: JSON.parse(localStorage.getItem("user"))
    };
  },
  methods: {
    async resendOtp() {
      try {
          const formData = {
            phone: JSON.parse(localStorage.getItem("user")).phone,
            phone_code: JSON.parse(localStorage.getItem("user")).phone_code
          };
          const response = await axios.post(`/send_code`, formData);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (error) {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        } finally {
          
        }
    },
    SubmitForm() {
      if (this.code === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
          timer: 6000,
        });
      } else {
        const otp = {
          phone: this.phone,
          phone_code: this.phone_code,
          code: this.code
        }
        axios
          .post("/check_code", otp)
          .then((data) => {
            // console.log(data);
            if (data.data.status == true) {
              //Perform Success Action
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.data.message,
                showConfirmButton: true,
                timer: 6000,
              });              
              localStorage.setItem("user", JSON.stringify(otp));
              this.$router.push("/reset_password");
            } else {
              //Perform Success Action
              Swal.fire({
                type: "error",
                position: "center",
                icon: "error",
                title: data.data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              this.loading = false;
            }
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: error.response.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            // this.$router.push({ path: "/teachers" });
            this.loading = false;
          })
          .finally(() => {
            //Perform action in always
          });
      }
    },
  },
  created() {
    if(this.userData == null) {
      this.$router.push("/forget_password")
    }
  }
};
</script>

<style scoped>
.auth h2 {
  text-align: start;
}
.auth .text {
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
}
.auth .submit_btn {
  margin-top: 0;
}
</style>
