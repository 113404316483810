<template>
  <navbar />
    <router-view></router-view>
  <Footer />
</template>

<script>
import axios from "axios";
import navbar from './components/layouts/navbar.vue';
import Footer from './components/layouts/footer.vue';

export default {
  name: 'App',
  components: {
    navbar,
    Footer,
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
    // this.$cartLengthFunction()
    axios.interceptors.response.use(null, function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.$router.push({ path: "/" });
        // this.$router.push("/login");
      }
      return Promise.reject(error);
    });
  },
}
</script>