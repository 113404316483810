<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="data_profile w-100">
      <h3 class="title_section mb-5">{{ $t("Order History") }}</h3>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="order_tabs">
            <router-link
              v-for="(order, index) in orders"
              :key="index"
              :to="`/my_order/${order.id}`"
            >
              <div class="details">
                <p class="id">#{{ order.id }}</p>
                <p class="price">{{ order.total_price }} {{ $t("egp") }}</p>
                <p class="count">{{ order.items_count }} {{ $t("Item") }}</p>
              </div>
              <div class="status_order">
                <p class="status">
                  {{
                    order.status == "pending"
                      ? $t("pending")
                      : order.status == "preparing"
                      ? $t("preparing")
                      : order.status == "out_for_delivery"
                      ? $t("Out For Delivery")
                      : order.status == "delivered"
                      ? $t("Delivered")
                      : order.status == "finished"
                      ? $t("Finished")
                      : "client_cancel"
                      ? $t("client_cancel")
                      : order.status == "provider_cancel"
                      ? $t("provider_cancel")
                      : order.status == "provider_reject"
                      ? $t("provider_reject")
                      : ""
                  }}
                </p>
                <p class="date">{{ order.created_at }}</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-lg-8 col-md-6 col-12">
          <div class="order_history mb-4">
            <h3 class="title mb-4">{{ $t("Order History") }}</h3>
            <div
              class="order_product_card"
              v-for="(item, index) in order.items"
              :key="index"
            >
              <img :src="item.product.image" :alt="item.product.image" />
              <p class="title">{{ item.product.title }}</p>
              <!-- {{ item.product.price }} -->
              <p class="price">0 {{ $t("egp") }}</p>
            </div>
          </div>
          <div class="order_details">
            <h3 class="title">{{ $t("Order Details") }}</h3>
            <ul class="details">
              <li>
                <p class="title">{{ $t("Order Number") }} :</p>
                <p class="text">{{ order.order_number }}</p>
              </li>
              <li>
                <p class="title">{{ $t("phone") }} :</p>
                <p class="text">{{ order.phone_number }}</p>
              </li>
              <li>
                <p class="title">{{ $t("Estimated Time") }} :</p>
                <p class="text">{{ order.estimated_time }}</p>
              </li>
              <li>
                <p class="title">{{ $t("Shipping Address") }} :</p>
                <p class="text">
                  {{ address.title }} - {{ city.title }} - {{ country.title }}
                </p>
              </li>
              <li>
                <p class="title">{{ $t("Payment Method") }}</p>
                <p class="text">{{ order.pay_type }}</p>
              </li>
            </ul>
            <div class="total_price">
              <p class="title">{{ $t("total") }}</p>
              <p class="text">{{ order.total_price }} {{ $t("egp") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      orders: [],
      order: {},
      address: {},
      city: {},
      country: {},
    };
  },
  methods: {
    fetchOrders() {
      axios
        .get("/orders")
        .then((response) => {
          this.orders = response.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    fetchOrderDetails() {
      let id = this.$route.params.id;
      axios
        .get(`/orders/${id}`)
        .then((response) => {
          this.order = response.data.data;
          this.address = response.data.data.address;
          this.city = response.data.data.address.city;
          this.country = response.data.data.address.city.country;
          // console.log(this.order)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetchOrders();
    this.fetchOrderDetails();
  },
  watch: {
    $route: {
      handler: "fetchOrderDetails",
      immediate: true,
    },
  },
};
</script>

<style>
.breadcrumb {
  margin-bottom: 4rem;
}
</style>
