<template>
  <section class="header_about">
    <div class="container">
      <img :src="contact_data.contact_us_image" alt="contact us header" />
      <div class="text_header">
        <h1>{{ $t("contact_us") }}</h1>
      </div>
    </div>
  </section>
  <section class="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
            <form v-on:submit.prevent="SubmitForm">
              <h2>{{$t("Get In Touch")}}</h2>
              <div class="form-group">
                <input type="text" name="" id="" class="form-control" v-model="Form.name" :placeholder="$t('your_name')+'*'" @input="filterNameCharacters" maxlength="100" :disabled="auth" required>
              </div>
              <div class="form-group">
                <input type="email" name="" id="" class="form-control" v-model="Form.email" :placeholder="$t('your_email')+'*'" maxlength="100" @input="validateEmail" :disabled="auth" required>
                <div class="error-message text-danger">{{ emailError }}</div>
              </div>
              <div class="form-group">
                <vue-tel-input v-model="phoneForm" class="form-control" v-bind="bindProps" :disabled="auth"></vue-tel-input>
                <!-- <input type="number" name="" id="" class="form-control" v-model="Form.phone" :placeholder="$t('your_phone')" maxlength="25" @input="filterInputNumber" required> -->
              </div>
              <div class="form-group">
                <textarea name="" id="" cols="30" rows="5" class="form-control" maxlength="190" v-model="Form.message" :placeholder="$t('write_message')+'*'" @input="filterMessageCharacters" required></textarea>
                <span class="max_length">{{Form.message.length}} / {{maxTextLength - Form.message.length}}</span>
              </div>
              <button type="submit" class="btn" :disabled="disableButton">{{$t("send")}}</button>
            </form>
            <socialMedia @dataLoaded="handleDataLoaded" />
        </div>
        <div class="col-lg-6 col-12">
          <iframe
            :src="contact_data.map_link"
            style="border:0;"
            allowfullscreen=""
            disableButton="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div class="contact_data">
            <p class="title"><i class="fa-solid fa-location-dot"></i> {{$t("address")}}</p>
            <span class="text" v-if="lang == 'en'">{{lang == 'ar' ? contact_data.address_ar : contact_data.address_en}}</span>
          </div>
          <div class="contact_data">
            <p class="title"><i class="fa-solid fa-envelope"></i> {{$t("email")}}</p>
            <a :href="`mailto:${contact_data.email}`" class="text">{{contact_data.email}}</a>
          </div>
          <div class="contact_data">
            <p class="title"><i class="fa-solid fa-phone"></i> {{$t("call_us")}}</p>
              <a :href="`tel:${contact_data.phone}`" class="text">{{contact_data.phone}}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import axios from "axios";
import Swal from "sweetalert2";
import socialMedia from '@/components/include/contact/social.vue';

export default {
  data() {
    return {
      Form: {
        name: this.$store.getters['isAuthenticated'] ? JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).name : "",
        email: this.$store.getters['isAuthenticated'] ? JSON.parse(localStorage.getItem("user")) == null ? "" : JSON.parse(localStorage.getItem("user")).email : "",
        message: "",
      },
      phoneForm: this.$store.getters['isAuthenticated'] ? JSON.parse(localStorage.getItem("user")) == null ? "" : "+"+JSON.parse(localStorage.getItem("user")).phone_code+JSON.parse(localStorage.getItem("user")).phone : "",
      bindProps: {
        mode: "international",
        defaultCountry: this.auth ? "" : "EG",
        autoDefaultCountry: this.auth ? false : true,
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          placeholder: this.$t("phone")+'*',
        },
        validCharactersOnly: true
      },
      maxTextLength: 190,
      lang: "",
      disableButton: false,
      emailError: "",
      auth: this.$store.getters['isAuthenticated'],
      contact_data: {},
    };
  },
  components: {
    VueTelInput,
    socialMedia
  },
  methods: {
    filterNameCharacters() {
      this.Form.name = this.Form.name.replace(/[^a-zA-Z\s]/g, "");;
    },
    filterMessageCharacters() {
      this.Form.message = this.Form.message.replace(/[^a-zA-Z\s]/g, "");;
    },
    validateEmail() {
      // Regular expression for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.Form.email) {
        this.emailError = this.$t('Email is required');
      } else if (!emailPattern.test(this.Form.email)) {
        this.emailError = this.$t('Invalid email format');
      } else {
        this.emailError = ''; // Reset the error message if email is valid
      }
    },
    handleDataLoaded(data) {
      this.contact_data = data;
      // console.log("Data received from child:", this.socialData);
    },
    SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.disableButton = true; //the disableButton begin
      
      const formData = new FormData();
      Object.entries(this.Form).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('phone_code', this.phoneForm.split(' ')[0].replace('+', ''));
      formData.append('phone', this.phoneForm.split(' ').slice(1).join(' ').replace(/\s/g, ''));
            
      axios
        .post("/contact_us", formData)
        .then((data) => {
          // console.log(data);
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "center",
              icon: "success",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            if (!this.auth) {
              this.Form.name = "",
              this.phoneForm = "",
              this.Form.email = ""
            }
            this.Form.message = "",
            this.disableButton = false;
          } else {
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: data.data.message,
              showConfirmButton: true,
              timer: 6000,
            });
            this.disableButton = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "center",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: true,
            timer: 6000,
          });
          // this.$router.push({ path: "/teachers" });
          this.disableButton = false;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  created() {
    this.lang = localStorage.getItem("lang");
  }
};
</script>