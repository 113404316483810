import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from "vue-i18n";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import router from "../router/router.js";
import axios from 'axios';
import FlagIcon from 'vue-flag-icon';
import VueSocialSharing from 'vue-social-sharing';
import rate from 'vue-rate';
import 'vue-rate/dist/vue-rate.css';
import store from '../src/store/index.js';
import vue3GoogleLogin from 'vue3-google-login';
import VueTelInput from 'vue-tel-input';
import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import 'vue-tel-input/vue-tel-input.css';
import "../src/assets/scss/style.min.css";
import setupAxios from './axios/axiosConfig'; // Import the axios configuration function

function loadLocaleMessages() {
    const locales = require.context(
      "./locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    return messages;
  }
  let lang = "en";
  if (localStorage.getItem("lang")) {
    lang = localStorage.getItem("lang");
  }
  
  const i18n = createI18n({
    locale: lang, // set locale
    allowComposition: true, // you need to specify that!
    fallbackLocale: lang, // set fallback locale
    messages: loadLocaleMessages(), // set locale messages
  });
  

const app = createApp(App);
app.use(VueSocialSharing);
app.use(rate);
app.use(Vue3FormWizard);
app.use(FlagIcon);
app.use(VueTelInput);
app.use(i18n);
app.use(router);
app.use(vue3GoogleLogin, {
  clientId: 'http://192.168.1.4:8080'
})
app.use(store);
const newLocal = lang;
axios.defaults.headers.common["Accept-Language"] = newLocal;

// Call the axios configuration function
setupAxios();
app.config.globalProperties.axios = axios;
app.config.globalProperties.$websiteUrl = "https://elsaygh.com/";

// Define a global function
// app.config.globalProperties.$cartLengthFunction = async function() {
//   try {
//     const response = await axios.get("/cart");
//     const cartItems = response.data.data.data;
//     const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
//     const event = new Event('call-component-function');
//     document.dispatchEvent(event);
//     // console.log("mainjs : " + totalQuantity)
//     return totalQuantity;
//   } catch (error) {
//     // console.error(error);
//     return 0;
//   }
// }

app.mount('#app');
