<template>
  <!-- Start Nav top -->
  <div class="nav-top">
    <div class="container">
      <router-link to="/" class="logo">
        <img src="@/assets/media/logo.png" alt="logo" />
      </router-link>
      <a class="lang" v-if="langStorage == 'en'" @click="changeLocale('ar')">
        <img class="icon" src="@/assets/media/egypt.png" alt="egypt">
        <span>{{ $t ("arabic")}}</span>
      </a>
      <a class="lang" v-else-if="langStorage == 'ar'" @click="changeLocale('en')">
        <img class="icon" src="@/assets/media/united-states.png" alt="united-states">
        <span>{{ $t ("english")}}</span>
      </a>
      <a class="lang" v-else @click="changeLocale('ar')">
        <img class="icon" src="@/assets/media/egypt.png" alt="egypt">
        <span>{{ $t ("arabic")}}</span>
      </a>
    </div>
  </div>
  <!-- End Nav top -->

  <!-- Start navbar -->
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link">{{ $t("home") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/products" class="nav-link">{{
              $t("products")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">{{
              $t("about_us")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact" class="nav-link">{{
              $t("contact_us")
            }}</router-link>
          </li>
        </ul>
        <div class="search" role="search">
          <div class="form-group">
            <input
              class="form-control"
              type="search"
              :placeholder="$t('search')"
              aria-label="Search"
              v-model="nameProduct"
              @keyup="searchProduct"
            />
            <div class="btn iconsearch" type="submit">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
          </div>
          <div class="result_product" v-if="nameProduct.length && productsSearch.length">
            <div @click="removeResult(product.id)" :to="`/product/${product.id}`" class="product_search_card" v-for="(product, index) in productsSearch" :key="index">
                <img :src="product.image" :alt="product.title">
                <div class="data">
                    <p class="title">{{product.title.substring(0, 100) + ' ...'}}</p>
                    <span class="text" v-html="product.text.substring(0, 100) + ' ...'"></span>
                    <div class="rate">
                        <i
                          class="fa-solid fa-star"
                          v-for="(star, item) in product.rate"
                          :key="item"
                        ></i>
                        <i
                          class="fa-regular fa-star"
                          v-for="(star, item) in 5 - product.rate"
                          :key="item"
                        ></i>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <router-link to="/compare">
            <img src="@/assets/media/icon/chart.svg" alt="chart">
          </router-link>
          <div class="dropdown" v-if="auth">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="@/assets/media/icon/user.svg" alt="user">
            </button>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/profile">{{$t("profile_info")}}</router-link></li>
              <li><router-link class="dropdown-item" to="/password">{{$t("change_password")}}</router-link></li>
              <li><router-link class="dropdown-item" to="/notification">{{$t("notification")}}</router-link></li>
              <li><router-link class="dropdown-item" to="/my_order">{{$t("my_order")}}</router-link></li>
              <li><router-link class="dropdown-item" to="/reviews">{{$t("my_product_reviews")}}</router-link></li>
              <button type="button" class="btn logout_btn" @click="removeToken">{{$t("logout")}}</button>
            </ul>
          </div>
          <div v-else class="dropdown">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="@/assets/media/icon/user.svg" alt="user">
            </button>
            <ul class="dropdown-menu">
              <li class="dropdown-item">
                <span>{{$t("Already have an account, then log in")}}</span>
                <router-link to="/login" class="btn login_btn">{{$t("Login")}}</router-link>
              </li>
              <li class="dropdown-item">
                <span>{{$t("New user, enjoy the benfits of being member")}}</span>
                <router-link to="/register" class="btn register_btn">{{$t("Register")}}</router-link>
              </li>
            </ul>
          </div>
          <router-link to="/wishlist">
            <img src="@/assets/media/icon/heart.svg" alt="heart">
          </router-link>
          <router-link to="/cart">
            <img src="@/assets/media/icon/cart.svg" alt="cart">
            <span class="count" v-if="totalQuantity >= 1">{{ totalQuantity >= 1 ? totalQuantity : "" }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
  <!-- End navbar -->
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: "navbar",
  data() {
    return {
      show: true,
      auth:false,
      langStorage: localStorage.getItem('lang'),
      products: [],
      nameProduct: "",
      productsSearch: [],
      countCart: "",
    };
  },
  methods: {
    async removeToken() {
      Swal.fire({
        title: this.$t("check_logout"),
        text: this.$t("are_you_sure_logout"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_logout"),
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(delete_id);
          axios.post(`/logout`).then(({ data }) => {
            // console.log(result);
            if (data.status == true) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.message,
                showConfirmButton: true,
                timer: 6000,
              });
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              location.reload();
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: data.message,
                showConfirmButton: true,
                timer: 6000,
              });
            }
            return data;
          });
        }
      });
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", locale);
      // this.$forceUpdate('lang',locale);
      localStorage.setItem("lang", locale);
      location.reload();
      // console.log(locale);
    },
    // Get Filter
    searchProduct() {
      if(this.nameProduct.length > 2){
        axios
            .get(`/search_product?keyword=${this.nameProduct}`)
            .then((res) => (
              this.productsSearch = res.data.data
            ))
            .catch((err) => console.log(err));
            // console.log(this.productsSearch);
      }
    },
    // Get Filter

    removeResult(product_id) {
      this.nameProduct == "";
      const currentRoute = this.$router.currentRoute;
      this.$router.push(`/product/${product_id}`).then(() => {
        if (this.$router.currentRoute.fullPath === currentRoute.fullPath) {
          window.location.reload();
        }
      });
    },
  },
  computed: {
    totalQuantity() {
      return this.$store.getters['totalQuantity'];
    }
  },
  created() {
    this.auth = this.$store.getters['isAuthenticated'];
    this.$store.dispatch('fetchCartLength'); // Fetch initial cart length on component creation
    // console.log("Auth : " + this.auth);
    
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
  },

  watch: {
    // Watch for changes in customFunction
  }
};
</script>