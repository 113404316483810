<template>
  <div class="product_cart_summary" v-for="(product, index) in products" :key="index">
    <img :src="product.product.image" :alt="product.product.title">
    <div class="product_details">
        <p class="title_product">{{product.product.title.substring(0, 50) + ' ...'}}</p>
        <!-- {{product.product.price}} -->
        <p class="price">0 {{$t("egp")}}</p>
        <p class="quantity">{{$t("quantity")}}: {{product.quantity}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["products"]
}
</script>

<style>

</style>