<template>
  <div class="card_cart">
    <div class="image">
      <img :src="cart.product.image" :alt="cart.product.title" />
      <div class="details">
        <h6 class="title">{{ cart.product.title }}</h6>
        <p class="text" v-html="cart.product.text.substring(0, 60) + ' ...'"></p>
      </div>
    </div>
    <!-- <h6 class="price">{{ cart.product.price }} {{$t("egp")}}</h6> -->
    <h6 class="price">0 {{$t("egp")}}</h6>
    <div class="quantity">
      <div class="minus" @click="minusQuantity(cart.id)">
        <i class="fa-solid fa-minus"></i>
      </div>
      <p class="number">{{ cart.quantity }}</p>
      <div class="plus" @click="addQuantity(cart.product.id)">
        <i class="fa-solid fa-plus"></i>
      </div>
    </div>
    <!-- <h6 class="sub_total">{{ cart.product.price * cart.quantity }} {{$t("egp")}}</h6> -->
    <h6 class="sub_total">0 {{$t("egp")}}</h6>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: "product-card",
  props: {
    cart: Object,
    callCartMethod: Function
  },
  methods: {
    async minusQuantity(item_id) {
      try {
        const response = await axios.post(`/minus_item/${item_id}`);
        // Swal.fire({
        //   title: "",
        //   text: response.data.message,
        //   icon: "success",
        // });
        this.data = response.data.data,
        this.callCartMethod();
      } catch (error) {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      } finally {
        
      }
    },

    async addQuantity(product_id) {
      const formData = new FormData();
      formData.append("product_id", product_id);
      formData.append("quantity", 1);

      try {
        const response = await axios.post("/cart", formData);
        // Swal.fire({
        //   title: "",
        //   text: response.data.message,
        //   icon: "success",
        // });
        this.data = response.data.data,
        this.callCartMethod();
      } catch (error) {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      } finally {
        
      }
    },
  }
};
</script>

<style></style>
