<template>
  <section class="privacy">
    <div class="container">
        <h2>{{$t("privacy")}}</h2>
        <p class="text" v-html="privacy.text"></p>
    </div>
  </section>
</template>


<script>
import { privacyData } from "@/api/static/privacy.js";

export default {
  data() {
    return {
      privacy: {},
    };
  },
  mounted() {
    privacyData().then((response) => {
      this.privacy = response.data.data;
      // console.log(response.data);
      return response;
    });
  },
};
</script>
